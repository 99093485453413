import './if-then.scss';

import loglevel from 'loglevel';
const log = loglevel.getLogger('if-then');

import _ from 'lodash';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {useInputState, useInputCallback} from '../hooks/useInputState';
import { useToggleState } from '../hooks/useToggleState';

import {FormControl, FormControlLabel, InputLabel, Menu, MenuItem, Select, Step, StepLabel, Stepper, Checkbox, TextField, Tabs, Tab, Modal, Dialog, DialogTitle, DialogContent, DialogActions, Toolbar, Box, Button, IconButton, Fab, Paper, ButtonGroup} from '@material-ui/core';

import { Store } from 'reactive-state';
import { ActionMap, connect } from 'reactive-state/react';

import { ColumnDefinition, compareKey, FieldDescriptor, FieldType, Key, parseKey, RawClient, stringifyKey, TableClient, FieldTypeCategory } from '@thinkalpha/table-client';

import { BehaviorSubject, combineLatest, from, Observable, Subscription } from 'rxjs';
import TableView from '../components/table-view/table-view.new';
import { AppState, clipboard$, Clipboard } from '../state';
import { ConcreteStrategy, IfThenStrategy, MaterializedStrategy, StrategyType } from '../strategy/model';
import { randomString } from '../util/randomString';
import { LogicalOperator, Operand, Operator, IfThenGroupModel, IfThenLineModel, IfThenLineDraftModel } from './model';
import {appConfig} from '../config/appConfig';

import IfThenGroup from './group';
import IfThenLine from './line';

import { Universe, UniverseAspectType } from '../universe/model';

import classnames from 'classnames';

import { defineStrategy } from '../strategy/definer';
import { defineUniverse } from '../universe/definer';
import {SpecialResult, UniverseSelector} from '../universe/universe-selector';

import { filter, flatMap, map, tap } from 'rxjs/operators';

import { LinkBar } from '../demo/link-bar';
import { getSavedStrategies, getStrategyById, playTransientStrategy, saveStrategy, StrategyRecord } from '../services/strategies';
import { allStrategyFields } from './fields.hardcoded';
import { DataBuilder, descriptorToField } from '../components/data-builder/data-builder';
import { CustomFormula } from '../components/data-builder/model';
import { useDeepEffect } from '../hooks/useDeepEffect';
import FilterEditor from '../components/filter-editor/filter-editor.new';
import ifThenHardcodedColumnPreferences from './preferences.hardcoded';
import Draggable from 'react-draggable';
import { ColumnPreference, TableUserData } from '../components/table-view/model';
import { AppDrawer } from '../app-drawer/app-drawer';

type Props = {
    tableKey?: Key;
    client: RawClient;
    showPreviewTab: boolean;
    // columnPreference?: (field: FieldDescriptor) => ColumnPreference | undefined;
    // columnPreferences: ColumnPreference[];
    savedStrategies: IfThenGroupModel[];
    addStrategy: (strategy: IfThenGroupModel) => void;
    deleteStrategy: (strategy: {name: string}) => void;
    clipboard: Clipboard;
    setClipboard: (clipboard: Clipboard) => void;
};

type Tab = 'builder' | 'preview' | 'script' | 'model';

type ModalData<T> = {
    title: string;
    message: string;
    onClose: (res: T) => void;
};

function defaultStrategy(): ConcreteStrategy<IfThenStrategy> {
    const strategy: ConcreteStrategy<IfThenStrategy> = {
        universe: undefined,
        strategy: {
            type: StrategyType.ifThen,
            root: {
                id: 'root',
                type: 'group',
                enabled: true,
                lines: [{type: 'group', id: randomString(), enabled: true, lines: [{type: 'line', enabled: true, id: randomString()}], operator: LogicalOperator.and, collapsed: false}],
                operator: LogicalOperator.or,
                collapsed: false
            },
            formulas: []
        }
    };
    Object.seal(strategy);
    return strategy;
}

const IfThenBuilder: React.FC<Props & RouteComponentProps<{key?: string}>> = ({
    tableKey, client, match, showPreviewTab = true, clipboard, setClipboard
}) => {

    const [strategy, setStrategy] = useState(defaultStrategy());
    const [theme, setTheme] = useState<'ag-theme-balham' | 'ag-theme-balham-dark'>('ag-theme-balham-dark');
    // const [uncommittedChanges, setUncommittedChanges] = useState(0);
    const [committedModel, setCommittedModel] = useState<ConcreteStrategy<IfThenStrategy>>();
    const [autocommit, setAutocommit] = useState<false | true | 'not-lines'>(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [fields, setFields] = useState<FieldDescriptor[]>([]);
    const [activeTab, setActiveTab] = useState<Tab>('builder');
    const [loadMenuShown, , showLoadMenu, hideLoadMenu, toggleLoadMenu] = useToggleState(false);
    const [saved, setSaved] = useState<StrategyRecord[] | undefined>(undefined);
    const [confirmationModal, setConfirmationModal] = useState<ModalData<boolean>>();
    const [notificationModal, setNotificationModal] = useState<ModalData<void>>();
    const [rowCount, setRowCount] = useState<number>();
    const [saveNameModal, setSaveNameModal] = useState<(name?: string) => void>();
    const [collapseBuilder, setCollapseBuilder, , , toggleCollapseBuilder ] = useToggleState(false);
    const [createColumnModal, setCreateColumnModal] = useState<(result?: {name: string, description?: string, formula: string}) => void>();
    // const [createHistoricColumnModal, setCreateHistoricColumnModal] = useState<(result?: {name: string, description?: string, formula: string}) => void>();
    const [tableState, setTableState] = useState<TableUserData>();
    const [key, setKey] = useState<Key>();
    const [error, setError] = useState<string>();
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [liveGlobalFilter, setLiveGlobalFilter] = useState<string>('');
    const [validModel, setValidModel] = useState(false);
    const [drawerShown,, showDrawer, hideDrawer, toggleDrawer] = useToggleState(false);

    const loadButtonRef = useRef<HTMLButtonElement>(null);

    const uncommittedChanges = useMemo(() => {
        const res = !_.isEqual(committedModel, strategy);
        return res;
    }, [committedModel, strategy]);

    const switchToBuilder = useCallback(() => setActiveTab('builder'), []);
    const switchToModel = useCallback(() => setActiveTab('model'), []);
    const switchToScript = useCallback(() => setActiveTab('script'), []);

    const refreshSaveList = useCallback(() => getSavedStrategies().pipe(map(x => x.filter(s => s.type === StrategyType.ifThen && s.name))).subscribe(setSaved), [setSaved]);

    const toggleTheme = useCallback(() => {
        setTheme(theme => theme === 'ag-theme-balham' ? 'ag-theme-balham-dark' : 'ag-theme-balham');
    }, []);

    const refreshPreview = useCallback(() => {
        log.info('refreshing preview');

        const universe = strategy.universe;

        playTransientStrategy(universe, strategy.strategy).subscribe(playedStrategy => {
            if (!playedStrategy) return;
            setError(undefined);
            setKey({sym: playedStrategy.tableName!, ex: 'T'});
        });
        setCommittedModel(strategy);

        log.debug('setting uncommitted changes to false');
        // indicateUncommittedChanges(false);
    }, [strategy]);

    const updateAllButLines = useCallback(() => {
        log.info('refreshing preview');

        const universe = strategy.universe;

        playTransientStrategy(universe, {...strategy.strategy, root: committedModel ? committedModel.strategy.root : defaultStrategy().strategy.root}).subscribe(playedStrategy => {
            if (!playedStrategy) return;
            setError(undefined);
            setKey({sym: playedStrategy.tableName!, ex: 'T'});
        });
        setCommittedModel(strategy);

        log.debug('setting uncommitted changes to false');
    }, [committedModel, strategy]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!autocommit) return;
        if (autocommit === 'not-lines') {
            updateAllButLines();
        } else {
            refreshPreview();
        }
        setAutocommit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autocommit]);
    // console.log('uncommitted changes', uncommittedChanges);

    useEffect(() => {
        refreshPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useDeepEffect(() => {
        // indicateUncommittedChanges(true);
        setUnsavedChanges(true);
    }, [strategy.strategy.root]);

    useEffect(() => {
        let key: Key;
        if (tableKey) {
            key = tableKey;
        } else if (match.params.key) {
            key = parseKey(match.params.key);
        } else {
            // key = {sym: 'MktDataBase', ex: 'T'};
            return;
        }

        setKey(key);
    }, [tableKey, match, client]);

    useEffect(() => {
        if (!key) return;

        const tableClient = new TableClient(client, key);
        const sub = tableClient.descriptor$.subscribe(setFields);
        tableClient.bounds = {firstRow: 0, windowSize: 1};

        return () => {
            sub.unsubscribe();
            tableClient.dispose();
        };
    }, [client, key]);

    // refresh the preview on initial load
    // const firstRefresh = useRef(false);
    useEffect(() => {
        // if (firstRefresh.current) return;
        refreshPreview();
        refreshSaveList();
        // firstRefresh.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // // creationErrorDialogRef: MatDialogRef<any, any>;

    const addFormula = useCallback(async () => {
        const columnPromise = new Promise<CustomFormula>((res, rej) => {
            setCreateColumnModal(() => res);
        }).then(res => {
            setCreateColumnModal(undefined);
            return res;
        });

        const formula = await columnPromise;
        if (!formula) return; // cancelled

        setStrategy(model => ({
            ...model,
            strategy: {
                ...(model && model.strategy),
                formulas: [...model.strategy.formulas.filter(x => x.name !== formula.name), formula]
            }
        }));
        setAutocommit('not-lines');
    }, []);

    const clear = useCallback(async () => {
        if (uncommittedChanges) {
            const promise = new Promise<boolean>((res, rej) => {
                setConfirmationModal({
                    title: 'Pending Changes',
                    message: 'Are you sure you want to clear the strategy builder? You have unsaved changes.',
                    onClose: res
                });
            });

            const goahead: boolean = await promise;
            setConfirmationModal(undefined);
            if (!goahead) return;
        }

        setStrategy(defaultStrategy());
    }, [uncommittedChanges]);

    const resave = useCallback(async () => {
        await saveStrategy(strategy).toPromise();
        refreshSaveList();
    }, [strategy, refreshSaveList]);

    const openSave = useCallback(async () => {
        const promise = new Promise<string>((res, rej) => {
            setSaveNameModal(() => res);
        });

        const name = await promise;
        setSaveNameModal(undefined);
        if (!name) return;

        setStrategy(model => ({...model, name}));
        await saveStrategy({...strategy, strategy: {...(strategy && strategy.strategy), name}}).toPromise();

        refreshSaveList();
    }, [strategy, refreshSaveList]);

    const load = useCallback((saved: StrategyRecord) => {
        hideLoadMenu();
        getStrategyById(saved.id).pipe(
            filter((x): x is MaterializedStrategy<IfThenStrategy> => !x ? false : x.strategy.type === StrategyType.ifThen)
        ).subscribe(strategy => {
            setStrategy(strategy);
            setAutocommit(true);
        });
    }, [hideLoadMenu]);

    const onStrategyUpdated = useCallback((newStrategy: IfThenGroupModel) => {
        log.debug('strategy updated with new root model', newStrategy);
        setStrategy(model => ({
            ...model,
            strategy: {
                ...model.strategy,
                root: newStrategy
            },
            tableName: undefined
        }));
        // setUncommittedChanges(true);
    }, []);

    const onUniverseChanged = useCallback(async (newUniverse: Universe | SpecialResult | undefined) => {
        if (typeof newUniverse === 'object') {
            setStrategy(strat => ({...strat, universe: newUniverse}));
            // indicateUncommittedChanges(true);
        } else if (newUniverse === undefined) {
            setStrategy(strat => ({...strat, universe: undefined}));
            // indicateUncommittedChanges(true);
        } else if (newUniverse === SpecialResult.create) {
            
        } else {
            // todo: special result such as create
        }
    }, []);

    const onGlobalFilterChanged = useCallback((value: string) => {
        setGlobalFilter(value); // todo: debounce
        if (!value.startsWith('=')) {
            setLiveGlobalFilter(value);
        } // else wait for parser result
    }, []);

    const convertedFields = fields.map(descriptorToField);

    const onGlobalFilterValidityChanged = useCallback((valid: boolean, value: string) => {
        if (valid) {
            setLiveGlobalFilter(value);
        }
    }, []);

    const columnPreferences: readonly ColumnPreference[] = useMemo(() => [
        ...ifThenHardcodedColumnPreferences.map(cp => ({
            ...cp,
            rank: cp.rank !== undefined ? cp.rank + 1 : undefined
        })),
        ...strategy.strategy.formulas.map(f => ({
            name: f.name,
            rank: 0
        }))
    ], [strategy.strategy.formulas]);

    const buildTab = <div id="build-tab">
        <div id="builder" className={classnames({collapsed: collapseBuilder})}>
            <IfThenGroup
                onRefreshPreview={refreshPreview}
                committedModel={committedModel && committedModel.strategy.root}
                onValidityChanged={setValidModel}
                depth={0}
                fields={fields.map(descriptorToField)}
                model={strategy.strategy.root}
                onModelChanged={onStrategyUpdated}
                clipboard={clipboard}
                setClipboard={setClipboard}
            />
        </div>
        <div id="preview">
            <div id="heading">
                <div className="global-filter">
                    <FilterEditor
                        placeholder="Filter"
                        equalsMode
                        dataTypeRequired={FieldTypeCategory.Boolean}
                        fields={convertedFields}
                        value={globalFilter}
                        onValueChange={onGlobalFilterChanged}
                        onValidityChange={onGlobalFilterValidityChanged}
                    />
                </div>
                <Button color="primary" variant="outlined" onClick={addFormula}>Build Custom Column&nbsp;&nbsp;<i className="fal fa-columns"/></Button>
                <div className="expand"/>
                {rowCount !== undefined && <div className="row-count"><strong>Results:</strong>&nbsp;&nbsp;{rowCount}</div>}
                <IconButton edge="end" size="small" onClick={toggleCollapseBuilder}>
                    <i className={classnames({fas: true, 'fa-expand-arrows': !collapseBuilder, 'fa-compress-arrows-alt': collapseBuilder})}/>
                </IconButton>
            </div>
            {activeTab === 'builder' && <TableView
                onRowCountChanged={setRowCount}
                theme={theme}
                tableKey={key}
                columnPreferences={columnPreferences}
                tableState={tableState}
                onStateChanged={setTableState}
                smartFilter={liveGlobalFilter}
            />}
        </div>
    </div>;

    const onModelEdit = useInputCallback((value: string) => {
        try {
            const parsed = JSON.parse(value);
            setStrategy(parsed);
        } catch {

        }
    }, []);

    const modelTab = <div id="model-tab">
        {uncommittedChanges ? <h3>Uncommitted Model <button onClick={refreshPreview}>Commit</button></h3> : <h3>Committed Model</h3>}
        {uncommittedChanges ? <textarea rows={25} style={{width: '100%'}} value={JSON.stringify(strategy, undefined, 2)} onChange={onModelEdit} /> : <pre>{JSON.stringify(strategy, undefined, 2)}</pre>}
        {uncommittedChanges && <>
            <h3>Committed Model</h3>
            <pre>{JSON.stringify(committedModel, undefined, 2)}</pre>
        </>}
    </div>;

    const universeDef = defineUniverse(strategy.universe || {aspects: [], inclusionList: [], exclusionList: []});
    const strategyDef = defineStrategy(universeDef.name, strategy);
    const scriptTab = <div id="script-tab">
        <pre>{JSON.stringify([...universeDef.definitions, ...strategyDef.definitions], undefined, 2)}</pre>
    </div>;

    const mainPage =
        <div className="main-page">
            <Box display="flex" alignItems="center" width="100%">
                <Box flexGrow={0} flexShrink={0} flexBasis="auto">
                    <Toolbar hidden={activeTab !== 'preview'}>
                        
                    </Toolbar>
                </Box>
            </Box>
            <div className="tab-content">
                <div hidden={activeTab !== 'builder'}>
                    {buildTab}
                </div>
                <div hidden={activeTab !== 'preview'}>
                    <div id="view-tab">
                        {activeTab === 'preview' && key && <TableView
                            theme={theme}
                            tableKey={key}
                            columnPreferences={columnPreferences}
                            tableState={tableState}
                            onStateChanged={setTableState}
                            filter={liveGlobalFilter}
                        />}
                    </div>
                </div>
                <div hidden={activeTab !== 'model'}>
                    {modelTab}
                </div>
                <div hidden={activeTab !== 'script'}>
                    {scriptTab}
                </div>
            </div>
        </div>;
        
    const notificationDialog =
        <Dialog open={!!notificationModal} onClose={() => notificationModal!.onClose()}>
            {notificationModal && <>
                <DialogTitle>{notificationModal.title}</DialogTitle>
                <DialogContent>{notificationModal.message}</DialogContent>
                <DialogActions>
                    <button color="primary" onClick={() => notificationModal.onClose()}>OK</button>
                </DialogActions>
            </>}
        </Dialog>;

    const confirmationDialog =
        <Dialog open={!!confirmationModal} onClose={() => confirmationModal!.onClose(false)}>
            {confirmationModal && <>
                <DialogTitle>{confirmationModal.title}</DialogTitle>
                <DialogContent>{confirmationModal.message}</DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" onClick={() => confirmationModal.onClose(true)}>Yes</Button>
                    <Button color="primary" variant="text" onClick={() => confirmationModal.onClose(false)}>No</Button>
                </DialogActions>
            </>}
        </Dialog>;

    const loadCallbacks = useMemo(() => saved ? saved.map(save => () => load(save)) : [], [load, saved]);
    const loadMenu =
        <Menu 
            open={loadMenuShown} 
            id="load-save-menu" 
            anchorEl={loadButtonRef.current}
            getContentAnchorEl={null}
            onClose={hideLoadMenu}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            {saved && saved.map((save, i) => <MenuItem key={save.name} onClick={loadCallbacks[i]}>{save.name}</MenuItem>)}
        </Menu>;

    return <>
        <AppDrawer onClose={hideDrawer} open={drawerShown}>
            <MenuItem onClick={switchToBuilder}>Builder</MenuItem>
            <MenuItem onClick={switchToModel}>Model</MenuItem>
            <MenuItem onClick={switchToScript} divider>Script</MenuItem>
                
            <MenuItem disabled>Table:&nbsp;<code>{stringifyKey(key)}</code></MenuItem>
            {appConfig.commit && <MenuItem disabled>Version:&nbsp;<code>{appConfig.commit.substr(0, 6)}</code></MenuItem>}
        </AppDrawer>
        <main id="if-then-page" className={classnames('drawerable', {'shift-for-drawer': drawerShown})} style={{position: 'relative'}}>
            <LinkBar onMenu={drawerShown ? undefined : toggleDrawer}>
                <ButtonGroup variant="outlined">
                    <Button onClick={clear}>New</Button>
                    <Button onClick={openSave}>Save As</Button>
                    <Button onClick={resave} disabled={!strategy.strategy.name || !unsavedChanges}>Save</Button>
                    <Button disabled={!saved || !saved.length} onClick={toggleLoadMenu} ref={loadButtonRef}>Load&nbsp;&nbsp;<i className="fas fa-caret-down"/></Button>
                </ButtonGroup>
                <div className="universe"><label>Universe:</label><UniverseSelector universe={strategy.universe} onChange={onUniverseChanged} allowUndefined={false} /></div>
            </LinkBar>
            <div className="page">
                {loadMenu}
                {mainPage}
            </div>
            {confirmationDialog}
            {notificationDialog}
            <SaveNameDialog onClose={saveNameModal} />
            <FormulaCreator fields={fields} onClose={createColumnModal} formulas={strategy.strategy.formulas} />
        </main>
    </>;
};

const SaveNameDialog: React.FC<{onClose?: (res?: string) => void}> = ({onClose}) => {
    const [name, setName, onNameChanged] = useInputState('');

    const save = useCallback(() => {
        if (onClose) onClose(name);
    }, [onClose, name]);

    const cancel = useCallback(() => onClose && onClose(), [onClose]);

    return <Dialog open={!!onClose} onClose={cancel}>
        <DialogTitle>Choose a name for this strategy</DialogTitle>
        <DialogContent>
            <TextField variant="standard" label="Strategy Name" value={name} onChange={onNameChanged} />
        </DialogContent>
        <DialogActions>
            <Button color="primary" variant="contained" onClick={save}>Save</Button>
            <Button color="primary" variant="text" onClick={cancel}>Cancel</Button>
        </DialogActions>
    </Dialog>;
};

export default connect(withRouter(IfThenBuilder), (store: Store<AppState>) => {
    const props = store.watch(state => ({
        savedStrategies: [],
        client: state.client,
        clipboard: state.clipboard
    }));

    const actionMap: ActionMap<typeof IfThenBuilder> = {
        setClipboard: clipboard$
        // addStrategy: addStrategy$,
        // deleteStrategy: deleteStrategy$
    };

    return {
        props,
        actionMap
    };
});

const FormulaCreator: React.FunctionComponent<{fields: readonly FieldDescriptor[], formulas?: readonly CustomFormula[], onClose: ((result?: CustomFormula) => void) | undefined}> = ({fields, onClose, formulas}) => {
    const mappedTableFields = fields.map(descriptorToField);
    const dedupedFields = _.uniqWith([...mappedTableFields, ...allStrategyFields], (x, y) => x.name === y.name && x.sourceTable === y.sourceTable);

    return <DataBuilder onClose={onClose} formulas={formulas} fields={dedupedFields} />;
};
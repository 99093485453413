import { Moment } from 'moment';
import { Field } from '../filter-editor/model';

export enum PeriodType {
    second = 's',
    minute = 'm',
    hour = 'h',
    day = 'd',
    week = 'w',
    month = 'M',
    quarter = 'Q',
    year = 'Y'
}

export const PeriodNames: readonly string[] = Object.keys(PeriodType);
Object.seal(PeriodNames);
export const PeriodNameMap: ReadonlyMap<PeriodType, string> = new Map<PeriodType, string>(PeriodNames.map(name => [PeriodType[name], name]));
Object.seal(PeriodNameMap);
export const PeriodTypes: readonly PeriodType[] = PeriodNames.map(x => PeriodType[x] as PeriodType);
Object.seal(PeriodTypes);

export type BarLength = SlidingWindow;
export interface SlidingWindow {
    value: number;
    period: PeriodType;
}

export type RelativePointInTime = null | {
    periods: SlidingWindow[];
    anchor: Moment | undefined;
}

export type AbsolutePointInTime = Moment;
export type CountPointInTime = number;

export type PointInTime = AbsolutePointInTime | RelativePointInTime | CountPointInTime;
export interface Timeframe {
    start: PointInTime;
    end?: PointInTime | null;
    barLength?: SlidingWindow;
}

export enum HistoryType {
    realtime = 'realtime',
    lookback = 'lookback',
    static = 'static',
    dynamic = 'dynamic',
    count = 'count'
}
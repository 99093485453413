import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {createPortal} from 'react-dom';

import loglevel from 'loglevel';
const log = loglevel.getLogger('completion-list');

import classNames from 'classnames';

import {CompletionOption, CompletionType, CompletionContext} from './model';
import {Position} from '../../util/position';
import {isInView} from '../../util/isInView';

import './completion-list.scss';
import _ from 'lodash';
import { FieldTypeCategoryNames } from '@thinkalpha/table-client';

type Props = {
    context: CompletionContext | undefined;
    position?: Position;
    onSelect: (option: CompletionOption) => void;
    onClose: () => void;
};

const symbolNameRegex = /^[0-9a-z%_$]+$/i;

function selectIcon(type: CompletionType) {
    switch (type) {
        case CompletionType.field:
            return 'columns';
        case CompletionType.function:
            return 'function';
        case CompletionType.syntax:
            return 'code';
        default:
            throw new Error('Unsupported completion type. No icon available.');
    }
}

// eslint-disable-next-line react/display-name
export const CompletionList = React.forwardRef<HTMLDivElement, Props>(({context, position: positionProp, onSelect, onClose}, ref) => {
    const [selectedIndex, setSelectedIndex] = useState<number>();
    const [position, setPosition] = useState<Position>();
    const ulRef = useRef<HTMLUListElement>(null);

    const options = useMemo(() => context && _(context.options)
        // .filter(x => x.type !== CompletionType.function)
        .orderBy([x => x.type, x => x.historic, x => x.text, x => x.source], ['asc', 'desc', 'asc', 'asc'])
        .value()
    , [context]);

    useEffect(() => {
        // delay this so blurs can't affect our click
        setTimeout(() => setPosition(positionProp), 0);
    }, [positionProp]);

    const hidden = position === undefined || !options || !options.length;

    const onCompletionItemSelected = useCallback((idx = selectedIndex) => {
        const option = options![idx];
        onSelect(option);
        setSelectedIndex(undefined);
    }, [onSelect, options, selectedIndex]);

    const keyHandler = useCallback((evt: KeyboardEvent) => {
        if (!options) return;

        if (evt.key === 'Down' || evt.key === 'ArrowDown') {
            setSelectedIndex(idx => (idx === undefined || idx >= options.length - 1) ? 0 : idx + 1);
            evt.preventDefault();
        } else if (evt.key === 'Up' || evt.key === 'ArrowUp') {
            setSelectedIndex(idx => (idx === undefined || idx === 0) ? options.length - 1 : idx - 1);
            evt.preventDefault();
        } else if (evt.key === 'Enter' || (evt.key === 'Tab' && selectedIndex !== undefined)) {
            onCompletionItemSelected();
            evt.preventDefault();
        } else if (evt.key === 'Esc' || evt.key === 'Escape') {
            onClose();
            evt.preventDefault();
        } else if (evt.key.length === 1) {
            const matches = symbolNameRegex.test(evt.key);
            if (!matches) { // non-alphanumeric character, so let it go through but also select the item
                onCompletionItemSelected();
                // event.preventDefault();
            }
        } else {
            // no-op
        }
    }, [onClose, onCompletionItemSelected, options, selectedIndex]);
    
    useEffect(() => {
        if (hidden) return;

        window.addEventListener('keydown', keyHandler);

        return () => {
            window.removeEventListener('keydown', keyHandler);
        };
    }, [keyHandler, hidden]);

    useEffect(() => {
        // make sure selected item is scrolled into view
        if (!ulRef.current) return;
        if (selectedIndex === undefined) return;
        const liNode = ulRef.current.childNodes.item(selectedIndex) as HTMLLIElement;
        if (!isInView(liNode, ulRef.current)) {
            liNode.scrollIntoView({block: 'nearest', inline: 'nearest'});
        }
    }, [selectedIndex]);

    const completionItemClickedHandlers = useMemo(() => (options || []).map((option, idx) => (event: React.MouseEvent<HTMLLIElement>) => {
        event.preventDefault();
        log.info('Completion item clicked', idx, option);
        onCompletionItemSelected(idx);
    }), [onCompletionItemSelected, options]);

    return createPortal((
        <div className="completion-list"
            ref={ref}
            hidden={hidden}
            style={{left: position && `${position.x}px`, top: position && `${position.y}px`, bottom: '1rem'}}>

            <div className="content">
                <ul ref={ulRef}>
                    {(options || []).map((option, i) => <li tabIndex={0} key={i} onClick={completionItemClickedHandlers[i]} className={classNames({'MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button': true, 'Mui-focusVisible': selectedIndex === i, 'completion-option': true, selected: selectedIndex === i, unselected: selectedIndex !== i})}>
                        <div className="text-line">
                            <div className="icon"><i className={`fa fa-${selectIcon(option.type)}`}/></div>
                            <div>{option.displayText || option.text}</div>
                            <div className="end-icons">
                                <i className="fal fa-history" hidden={!option.historic}/>
                                {/* <i className="far fa-chart-bar" hidden={!option.historic}/> */}
                            </div>
                        </div>
                        {option.description && <div className="description" hidden={selectedIndex !== i}>{option.description}</div>}
                        {option.source && <div className="source" hidden={selectedIndex !== i}><label>From</label>{option.source}</div>}
                        <div className="data-type" hidden={selectedIndex !== i}><label>Type</label>{FieldTypeCategoryNames.get(option.dataType)}</div>
                    </li>)}
                </ul>
                {context && context.functionContext && <div className="function-context">
                    <div className="function-def">
                        <span className="function-name">{context.functionContext.name}</span>
                        <span className="paren">(</span>
                        <span className="params">
                            {context.functionContext.params && context.functionContext.params.map((p, i) => <span className={classNames({param: true, current: context.functionContext!.paramIndex === i})} key={i}>
                                <span className="param-name">{p.name || `p${i}`}</span>
                                <span className="param-name-type-separator">:&nbsp;</span>
                                <span className="param-type">{FieldTypeCategoryNames.get(p.dataType)!}</span>
                                {p.optional && <span className="param-optional">(optional)</span>}
                                {i !== context.functionContext!.params!.length - 1 ? <span className="param-separator">,&nbsp;</span> : null}
                            </span>)}
                        </span>
                        <span className="paren">)</span>
                    </div>
                    {context.functionContext.paramIndex !== undefined
                        && context.functionContext.params
                        && context.functionContext.params[context.functionContext.paramIndex]
                        && context.functionContext.params[context.functionContext.paramIndex].description
                        && <div className="param-desc">
                            {context.functionContext.params[context.functionContext.paramIndex].description}
                        </div>}
                </div>}
            </div>
            <div className="filler"/>
        </div>
    ), document.body);
});

export default CompletionList;
import { DefinitionBundle } from '@thinkalpha/table-client';

import {timeframeToSubscript, pointInTimeToSubscript} from '../../../components/timeframe/pointInTime';
import { randomString } from '../../../util/randomString';
import { StrategyDefiner } from '../../definer';
import { PriceChangeStrategy, PriceRelativeChangeStrategy, StrategyCreatorResult } from '../../model';

import { defaultColumns } from '../defaultCols';

const viewMoniker = 'strategy';

export const createPriceChangeStrategy: StrategyDefiner<PriceChangeStrategy | PriceRelativeChangeStrategy> = (universeTableName: string, strategy: PriceChangeStrategy | PriceRelativeChangeStrategy) => {
    const intermediateName = randomString();
    const name = randomString();

    const definitions: DefinitionBundle = [
        {
            table: {
                name: intermediateName,
                auto_sources: [
                    {
                        name: universeTableName,
                        columns: [
                            // 'OfficialPrice'
                        ]
                    },
                    {
                        name: 'allUniverse',
                        columns: ['.*'],
                        match_key: {
                            primary: [
                                {
                                    on_left_side: 'symbol',
                                    on_right_side: 'symbol',
                                    venue: '*'
                                }
                            ]
                        }
                    },
                    {
                        name: 'indicators_prices',
                        columns: [
                            'prices_close_0'
                        ],
                        match_key: {
                            primary: [
                                {
                                    on_left_side: 'symbol',
                                    on_right_side: 'symbol'
                                }
                            ]
                        }
                    }
                ],
                row_filter: {
                    match_by: 'fullkey',
                    pattern: '.*'
                },
                formulas: [
                    {
                        name: 'OldLastPrice',
                        subscript: pointInTimeToSubscript(strategy.lookback),
                        formula: 'at(BarRecordOpen@BarRecord(_1m))',
                        // formula: 'at(TradePrice@TradeUpdate)',
                        match_key: {
                            primary: [
                                {
                                    on_left_side: 'symbol',
                                    on_right_side: 'symbol',
                                    venue: '*'
                                }
                            ]
                        }
                    },
                    {
                        name: 'PastPrice',
                        formula: 'OldLastPrice <= 0 ? { Close_adj } { OldLastPrice }'
                    }
                ]
            },
        }, {
            views: [
                {
                    table: intermediateName,
                    name: viewMoniker,
                    filter: `abs(PastPrice - Last_Price) > ${strategy.amount}`
                }
            ]
        }, {
            table: {
                name,
                auto_sources: [
                    {
                        name: intermediateName,
                        view: viewMoniker,
                        columns: ['.*'],
                        match_key: {
                            primary: [
                                {
                                    on_left_side: 'symbol',
                                    on_right_side: 'symbol',
                                    venue: '*'
                                }
                            ]
                        }
                    }
                ]
            }
        },
    ];

    return {definitions, name};
};
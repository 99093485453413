import loglevel from 'loglevel';

interface ConfigType {
    proxy: string;
    login: string;
    api: string;
    appVersion: string | undefined;
    environmentName: string;
}

export const localConfig: ConfigType = {
    // proxy: 'wss://ps2.thinkalpha.io:63450', // dev
    // proxy: 'wss://ps2.thinkalpha.io:63250', // uat
    proxy: 'wss://ps2.thinkalpha.io:63550', // demo
    login: 'https://ps2.thinkalpha.io:63455/login',
    api: 'https://api.dev.thinkalpha.io',
    appVersion: 'local',
    environmentName: 'local'
};

export const devConfig: ConfigType = {
    proxy: 'wss://ps2.thinkalpha.io:63450',
    login: 'https://ps2.thinkalpha.io:63455/login',
    api: 'https://api.dev.thinkalpha.io',
    appVersion: undefined,
    environmentName: 'dev'
};

export const dev3Config: ConfigType = {
    proxy: 'wss://ps2.thinkalpha.io:63750',
    login: 'https://ps2.thinkalpha.io:63755/login',
    api: 'https://api.dev.thinkalpha.io',
    appVersion: undefined,
    environmentName: 'dev'
};

export const demoConfig: ConfigType = {
    proxy: 'wss://ps2.thinkalpha.io:63550',
    // proxy: 'wss://ps2.thinkalpha.io:63450',
    login: 'https://ps2.thinkalpha.io:63455/login',
    api: 'https://api.dev.thinkalpha.io',
    appVersion: undefined,
    environmentName: 'dev'
};

export const uatConfig: ConfigType = {
    proxy: 'wss://ps2.thinkalpha.io:63250',
    login: 'https://ps2.thinkalpha.io:63455/login',
    api: 'https://api.uat.thinkalpha.io',
    appVersion: undefined,
    environmentName: 'uat'
};

export const qaConfig: ConfigType = {
    proxy: 'wss://ps2.thinkalpha.io:63150',
    login: 'https://ps2.thinkalpha.io:63155/login',
    api: 'https://api.qa.thinkalpha.io',
    appVersion: undefined,
    environmentName: 'qa'
};

export const prodConfig: ConfigType = {
    proxy: 'wss://ps2.thinkalpha.io:63350',
    login: 'https://ps2.thinkalpha.io:63355/login',
    api: 'https://api.dev.thinkalpha.io',
    appVersion: undefined,
    environmentName: 'prod'
};

export const prod2Config: ConfigType = {
    proxy: 'wss://ps2.thinkalpha.io:63850',
    login: 'https://ps2.thinkalpha.io:63855/login',
    api: 'https://api.dev.thinkalpha.io',
    appVersion: undefined,
    environmentName: 'prod2'
};

declare global {
    const APP_CONFIG_NAME: string;
    const APP_VERSION: string;

    interface Window {
        appConfig: ConfigType;
    }
}

let appConfig: ConfigType & {commit?: string};
try {
    if (APP_CONFIG_NAME === 'prod2') {
        appConfig = prod2Config;
    } else if (APP_CONFIG_NAME === 'prod' || APP_CONFIG_NAME === 'master') {
        appConfig = prodConfig;
    } else if (APP_CONFIG_NAME === 'dev') {
        appConfig = devConfig;
    } else if (APP_CONFIG_NAME === 'qa') {
        appConfig = qaConfig;
    } else if (APP_CONFIG_NAME === 'uat') {
        appConfig = uatConfig;
    } else if (APP_CONFIG_NAME === 'demo' || APP_CONFIG_NAME === 'alphaedge') {
        appConfig = demoConfig;
        appConfig.environmentName = APP_CONFIG_NAME;
    } else {
        appConfig = localConfig;
        // appConfig = prod2Config;
    }
} catch (e) {
    if (e instanceof ReferenceError) {
        loglevel.error('Failed to identify the app config name! Defaulting to dev.');
        appConfig = devConfig;
    } else {
        throw e;
    }
}

if (typeof APP_VERSION !== 'undefined') {
    appConfig.appVersion = APP_VERSION;
} else {
    loglevel.warn('Missing app version constant');
}

window.appConfig = appConfig;

export {appConfig};
export default appConfig;appConfig.commit = '96fd8960cb9354cf8dca87e121dccfeafbb524c9';

import { IfThenLineDraftModel, IfThenGroupModel } from './../if-then/model';
import { ConnectionStatus, RawClient } from '@thinkalpha/table-client';
import { Store } from 'reactive-state';
import { Subject } from 'rxjs';

// import {IfThenStrategy} from '../if-then/model';

import { enableDevTool } from 'reactive-state/src/devtool';

import loglevel from 'loglevel';
const logger = loglevel.getLogger('store');
logger.setDefaultLevel(loglevel.levels.INFO);

import { StoredUserConfig, storedUserConfig$ } from '../services/userStoredConfig';
import { ConcreteStrategy, KnownStrategyTemplate } from '../strategy/model';
import { Universe } from '../universe/model';

export type Clipboard = IfThenLineDraftModel | IfThenGroupModel;

export interface AppState {
    client?: RawClient;
    connectionStatus?: ConnectionStatus;
    accessToken?: string;

    currentStrategy?: ConcreteStrategy;
    currentUniverse?: Universe;

    storedUserConfig?: StoredUserConfig;
    clipboard?: Clipboard;
}

const initialState: AppState = {
    // strategies: []
};

export const store = Store.create(initialState);
export default store;

try {
    enableDevTool(store);
} catch (e) {
    logger.warn('Encountered error while enabling devtools', e);
}

store.select().subscribe(newState => logger.debug('STATE:', newState));

// tslint:disable-next-line:no-string-literal
window['store'] = store;

export const currentStrategy$ = new Subject<ConcreteStrategy | undefined>();
store.addReducer(currentStrategy$, (state, strategy) => ({...state, currentStrategy: strategy}), 'switch to strategy');

export const currentUniverse$ = new Subject<Universe | undefined>();
store.addReducer(currentUniverse$, (state, universe) => ({...state, currentUniverse: universe}), 'switch to universe');

export const clipboard$ = new Subject<Clipboard>();
store.addReducer(clipboard$, (state, clipboard) => ({...state, clipboard }), 'copied to clipboard');

store.addReducer(storedUserConfig$, (state, c) => ({...state, storedUserConfig: c}), 'setUserConfig');

import { FieldTypeCategory, Key } from '@thinkalpha/table-client';
import {DeepPartial} from 'ts-essentials';

export enum Operator {
    'like' = 'like',
    '==' = '==',
    '!=' = '!=',
    '<' = '<',
    '<=' = '<=',
    '>' = '>',
    '>=' = '>='
}

export enum LogicalOperator {
    'and' = 'and',
    'or' = 'or'
}

export type Operand = string;// | number | {field: string} | {expression: string} | undefined;

export interface IfThenPart {
    enabled: boolean;
    id: string;    
}

export interface IfThenLineModel extends IfThenPart {
    type: 'line';
    operand1: Operand;
    operator: Operator;
    operand2: Operand;
}

export interface IfThenLineDraftModel extends IfThenPart {
    type: 'line';
    operand1?: Operand;
    operator?: Operator;
    operand2?: Operand;
}

export interface IfThenLinePlaceholder extends IfThenPart {
    type: 'line';
}

export interface IfThenGroupModel extends IfThenPart {
    type: 'group';
    collapsed: boolean;
    operator: LogicalOperator;
    lines: (IfThenLineModel | IfThenGroupModel | IfThenLinePlaceholder)[];
}

export type EditableStrategyGroup = DeepPartial<IfThenGroupModel>;

export interface Condition extends IfThenGroupModel {
    name?: string;
}
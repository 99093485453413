import _ from 'lodash';

import { FieldDescriptor, FieldTypeCategory, TypesInTypeCategory, FieldTypeCategoryNames, CategoryForFieldType } from '@thinkalpha/table-client';
import * as Ast from './ast';
import { RangeType, RangeInfo } from './ast';

export function getRanges(node: Ast.AstNode | null | undefined, allowedType?: FieldTypeCategory, depth = 0): ({depth: number} & RangeInfo)[] {
    if (!node) return [];
    switch (node.type) {
        case Ast.AstNodeType.binaryOperation: {
            const n = node as Ast.BinaryOperationNode;
            return [
                {range: n.range, rangeType: RangeType.binaryOperation, source: node, allowedType, depth},
                {range: n.operatorRange, rangeType: RangeType.operator, source: node, allowedType, depth: depth + 1},
                {range: n.operand1Range, rangeType: RangeType.operand, source: node, allowedType, depth: depth + 1, index: 0},
                {range: n.operand2Range, rangeType: RangeType.operand, source: node, allowedType, depth: depth + 1, index: 1},
                ...getRanges(n.operand1, allowedType, depth + 2),
                ...getRanges(n.operand2, allowedType, depth + 2),
            ];
        }
        case Ast.AstNodeType.column:
            return [
                {range: node.range, rangeType: RangeType.column, source: node, allowedType, depth}
            ];
        case Ast.AstNodeType.number:
            return [
                {range: node.range, rangeType: RangeType.number, source: node, allowedType, depth}
            ];
        case Ast.AstNodeType.string:
            const n = node as Ast.StringNode;
            return [
                {range: node.range, rangeType: RangeType.string, source: node, allowedType, depth},
                {range: n.textRange, rangeType: RangeType.text, source: node, depth: depth + 1}
            ];
        case Ast.AstNodeType.functionCall: {
            const n = node as Ast.FunctionCallNode;
            return [
                {range: node.range, rangeType: RangeType.functionCall, source: node, allowedType, depth},
                {range: n.argumentsRange, rangeType: RangeType.arguments, source: node, depth: depth + 1},
                {range: n.functionNameRange, rangeType: RangeType.functionName, source: node, depth: depth + 1},
                {range: n.openParenRange, rangeType: RangeType.openParen, source: node, depth: depth + 1},
                ...(n.closeParenRange ? [{range: n.closeParenRange, rangeType: RangeType.closeParen, source: node, depth: depth + 1}] : []),
                ...(n.argumentRanges ? n.argumentRanges.map((ar, i) => ({range: ar, rangeType: RangeType.argument, source: node, allowedType: n.functionDef && n.functionDef.params[i] && n.functionDef.params[i].type || undefined, depth: depth + 1, index: i})) : []),
                ...(n.arguments ? _.flatMap(n.arguments, (an, i) => getRanges(an, n.functionDef && n.functionDef.params[i] && n.functionDef.params[i].type || undefined, depth + 2)) : [])
            ];
        }
        case Ast.AstNodeType.unaryOperation: {
            const n = node as Ast.UnaryOperationNode;
            return [
                {range: n.range, rangeType: RangeType.unaryOperation, source: node, allowedType, depth},
                {range: n.operandRange, rangeType: RangeType.operand, source: node, allowedType, depth: depth + 1, index: 0},
                ...getRanges(n.operand, allowedType, depth + 2),
            ];
        }
        case Ast.AstNodeType.paren: {
            const n = node as Ast.ParentheticalNode;
            return [
                {range: n.range, rangeType: RangeType.parenthetical, source: node, allowedType, depth},
                {range: n.contentRange, rangeType: RangeType.content, source: node, allowedType, depth: depth + 1},
                ...getRanges(n.content, allowedType, depth + 2),
            ];
        }
        default:
            throw new Error(`Unknown AST node type: ${node.type}`);
    }
}
import * as Ast from './ast';
import { NumericComparisonOperator, MathOperator } from './symbols';
import { FieldTypeCategory } from '@thinkalpha/table-client';
import { SlidingWindow } from '../timeframe/model';
import { HistoricType } from './model';
import { barToString } from '../timeframe/bars';
import _ from 'lodash';
import { randomString } from '../../util/randomString';

export interface HoistedColumn {
    name: string;
    formula: string;    
}

export interface CompiledImport {
    sourceTable: string | undefined;
    name: string;
}

export interface CompilationResult {
    result: string;
    hoistedColumns: HoistedColumn[];
    imports: CompiledImport[];
}

export interface CompilationOptions {
    barLength?: SlidingWindow | undefined;
    hoist: boolean;
}

export function compile(node: Ast.AstNode | null | undefined, options: CompilationOptions): CompilationResult {
    if (!node) return { result: '', hoistedColumns: [], imports: [] };
    if (!node.valid) return { result: '', hoistedColumns: [], imports: [] };

    const {barLength, hoist} = options;

    switch (node.type) {
        case Ast.AstNodeType.binaryOperation: {
            const {operand1, operand2, operator} = node as Ast.BinaryOperationNode;
            const compiledOperand1 = compile(operand1, options);
            const compiledOperand2 = compile(operand2, options);
            if (operator in NumericComparisonOperator && operand1.dataType === FieldTypeCategory.String) {
                return {
                    result: `(str_cmp(${compiledOperand1.result}, ${compiledOperand2.result}) ${operator} 0)`,
                    hoistedColumns: [...compiledOperand1.hoistedColumns, ...compiledOperand2.hoistedColumns],
                    imports: [...compiledOperand1.imports, ...compiledOperand2.imports]
                };
            }
            if (operator === MathOperator['%']) {
                return {
                    result: `(mod(${compiledOperand1.result}, ${compiledOperand2.result}))`,
                    hoistedColumns: [...compiledOperand1.hoistedColumns, ...compiledOperand2.hoistedColumns],
                    imports: [...compiledOperand1.imports, ...compiledOperand2.imports]
                };
            }
            return {
                result: `(${compiledOperand1.result} ${operator} ${compiledOperand2.result})`,
                hoistedColumns: [...compiledOperand1.hoistedColumns, ...compiledOperand2.hoistedColumns],
                imports: [...compiledOperand1.imports, ...compiledOperand2.imports]
            };
        }
        case Ast.AstNodeType.column: {
            const {name, sourceTable, field} = node as Ast.ColumnNode;
            if (field && field.historic === HistoricType.bars && !barLength) {
                throw new Error('No bar length specified, but a bar column was used.');
            }
            return {
                result: `(${name}${sourceTable ? `@${sourceTable}` : ''}${barLength ? `(${barToString(barLength)})` : ''})`,
                hoistedColumns: [],
                imports: []
            };
        }
        case Ast.AstNodeType.number: {
            const {value} = node as Ast.NumberNode;
            return {
                result: `(${value})`,
                hoistedColumns: [],
                imports: []
            };
        }
        case Ast.AstNodeType.string: {
            const {value, isRegex} = node as Ast.StringNode;
            const prefix = isRegex ? '%' : '$';
            return {
                result: `('${prefix}${value}')`,
                hoistedColumns: [],
                imports: []
            };
        }
        case Ast.AstNodeType.functionCall: {
            const {functionName, functionDef, arguments: args} = node as Ast.FunctionCallNode;
            const compiledArgs = args!.map(x => compile(x, options));
            const formula = `(${functionName}(${compiledArgs.map(x => x.result).join(', ')}))`;
            if (functionDef?.hoist && options.hoist) {
                const hoistName = `__${randomString()}`;
                return {
                    result: `(${hoistName})`,
                    hoistedColumns: [
                        {
                            name: hoistName,
                            formula
                        }, ..._.flatMap(compiledArgs, x => x.hoistedColumns)
                    ],
                    imports: _.flatMap(compiledArgs, x => x.imports),
                };
            } else {
                return {
                    result: formula,
                    hoistedColumns: _.flatMap(compiledArgs, x => x.hoistedColumns),
                    imports: _.flatMap(compiledArgs, x => x.imports),
                };    
            }
        }
        case Ast.AstNodeType.unaryOperation: {
            const {operator, operand} = node as Ast.UnaryOperationNode;
            const compiledOperand = compile(operand, options);
            return {
                result: `(${operator}${compiledOperand.result})`,
                hoistedColumns: compiledOperand.hoistedColumns,
                imports: compiledOperand.imports
            };
        }
        case Ast.AstNodeType.paren: {
            const {content} = node as Ast.ParentheticalNode;
            const compiledContent = compile(content, options);
            return {
                result: `(${compiledContent.result})`,
                hoistedColumns: compiledContent.hoistedColumns,
                imports: compiledContent.imports
            };
        }
        default:
            throw new Error(`Unknown AST node type: ${node.type}`);
    }
}

export default compile;
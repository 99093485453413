import { FieldTypeCategory } from '@thinkalpha/table-client';

export interface FunctionDef {
    name: string;
    description?: string;
    params: ParameterDef[];
    returnType: FieldTypeCategory;
    hoist: boolean;
}

export interface ParameterDef {
    name?: string;
    type: FieldTypeCategory;
    optional?: boolean;
}

export const functionDefs: readonly FunctionDef[] = [
    /// built-in operators: Key, Precedence, Associativity, isFunction, ReturnType, Args
    {name: 'pass', returnType: FieldTypeCategory.Double, params: [], hoist: false},
    {name: 'smaller', returnType: FieldTypeCategory.Boolean, params: [{type: FieldTypeCategory.Double}, {type: FieldTypeCategory.Double}], hoist: false},
    {name: 'larger', returnType: FieldTypeCategory.Boolean, params: [{type: FieldTypeCategory.Double}, {type: FieldTypeCategory.Double}], hoist: false},
    {name: 'mean', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Double}, {type: FieldTypeCategory.Double}], hoist: false},
    {name: 'pow', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Double}, {type: FieldTypeCategory.Double}], hoist: false},
    {name: 'mod', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Double}, {type: FieldTypeCategory.Double}], hoist: false},
    {name: 'exp', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Double}], hoist: false},
    {name: 'log', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Double}], hoist: false},
    {name: 'sqrt', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Double}], hoist: false},
    {name: 'ceil', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Double}], hoist: false},
    {name: 'floor', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Double}], hoist: false},
    {name: 'abs', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Double}], hoist: false},
    // rounded_value = Round(value, precision)
    {name: 'round', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Double}, {type: FieldTypeCategory.Double}], hoist: false},

    /// bitwise ops
    {name: 'bit_not', returnType: FieldTypeCategory.Bitmask, params: [{type: FieldTypeCategory.Bitmask}], hoist: false},
    // updated_cumulative_mask = SetBit(cumulative_mask, old_mask, new_mask) <- updates cumulative_mask with the diff of old_mask and new_mask
    {name: 'set_bit', returnType: FieldTypeCategory.Bitmask, params: [{type: FieldTypeCategory.Bitmask}, {type: FieldTypeCategory.Bitmask}, {type: FieldTypeCategory.Bitmask}], hoist: false},
    /// comparison/test
    {name: 'bit_eq', returnType: FieldTypeCategory.Boolean, params: [{type: FieldTypeCategory.Bitmask}, {type: FieldTypeCategory.Bitmask}], hoist: false},
    {name: 'bit_not_eq', returnType: FieldTypeCategory.Boolean, params: [{type: FieldTypeCategory.Bitmask}, {type: FieldTypeCategory.Bitmask}], hoist: false},
    {name: 'bit_contains', returnType: FieldTypeCategory.Boolean, params: [{type: FieldTypeCategory.Bitmask}, {type: FieldTypeCategory.Bitmask}], hoist: false},
    {name: 'bit_exclude', returnType: FieldTypeCategory.Boolean, params: [{type: FieldTypeCategory.Bitmask}, {type: FieldTypeCategory.Bitmask}], hoist: false},
    {name: 'bit_name', returnType: FieldTypeCategory.String, params: [{type: FieldTypeCategory.Bitmask}], hoist: false},

    /// timestamp ops
    /// manip
    {name: 'time_add', returnType: FieldTypeCategory.Timestamp, params: [{type: FieldTypeCategory.Timestamp}, {type: FieldTypeCategory.Double}], hoist: false},     // +/- nanos
    {name: 'time_diff', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Timestamp}, {type: FieldTypeCategory.Timestamp}], hoist: false},     // returns nanos
    {name: 'time_diff_from_now', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Timestamp}], hoist: false},     // returns nanos
    {name: 'time_smaller', returnType: FieldTypeCategory.Boolean, params: [{type: FieldTypeCategory.Timestamp}, {type: FieldTypeCategory.Timestamp}], hoist: false},
    {name: 'time_larger', returnType: FieldTypeCategory.Boolean, params: [{type: FieldTypeCategory.Timestamp}, {type: FieldTypeCategory.Timestamp}], hoist: false},
    /// format
    /// comparison

    /// enum ops
    {name: 'enum_eq', returnType: FieldTypeCategory.Boolean, params: [{type: FieldTypeCategory.Enum}, {type: FieldTypeCategory.Enum}], hoist: false},
    {name: 'enum_not_eq', returnType: FieldTypeCategory.Boolean, params: [{type: FieldTypeCategory.Enum}, {type: FieldTypeCategory.Enum}], hoist: false},
    {name: 'enum_name', returnType: FieldTypeCategory.String, params: [{type: FieldTypeCategory.Enum}], hoist: false},

    /// string ops
    {name: 'str_null', returnType: FieldTypeCategory.String, params: [{type: FieldTypeCategory.String}], hoist: false},
    // -1|0|1 = Cmp(str1, str2)
    {name: 'str_cmp', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.String}, {type: FieldTypeCategory.String}], hoist: false},
    // 0|1 = InStr(str, search_str)
    {name: 'in_str', returnType: FieldTypeCategory.Boolean, params: [{type: FieldTypeCategory.String}, {type: FieldTypeCategory.String}], hoist: false},
    // 0|1 = StrMatch(str, regex_str)   <- !!! 2nd param must be of REGEX category
    {name: 'str_match', returnType: FieldTypeCategory.Boolean, params: [{type: FieldTypeCategory.String}, {type: FieldTypeCategory.Regex}], hoist: false},
    // dyn_string = Concat2(str1, str2)
    {name: 'concat2', returnType: FieldTypeCategory.String, params: [{type: FieldTypeCategory.String}, {type: FieldTypeCategory.String}], hoist: false},
    // dyn_string = Concat3(str1, str2, str3)
    {name: 'concat3', returnType: FieldTypeCategory.String, params: [{type: FieldTypeCategory.String}, {type: FieldTypeCategory.String}, {type: FieldTypeCategory.String}], hoist: false},
    // dyn_string = Concat4(str1, str2, str3, str4)
    {name: 'concat4', returnType: FieldTypeCategory.String, params: [{type: FieldTypeCategory.String}, {type: FieldTypeCategory.String}, {type: FieldTypeCategory.String}, {type: FieldTypeCategory.String}], hoist: false},
    // dyn_string = Concat5(str1, str2, str3, str4, str5)
    {name: 'concat5', returnType: FieldTypeCategory.String, params: [{type: FieldTypeCategory.String}, {type: FieldTypeCategory.String}, {type: FieldTypeCategory.String}, {type: FieldTypeCategory.String}, {type: FieldTypeCategory.String}], hoist: false},
    // dyn_string = SubstrA(str, separator_str) <- returns substring of str after separator_str
    {name: 'substr_after', returnType: FieldTypeCategory.String, params: [{type: FieldTypeCategory.String}, {type: FieldTypeCategory.String}], hoist: false},
    // dyn_string = SubstrB(str, separator_str) <- returns substring of str before separator_str
    {name: 'substr_before', returnType: FieldTypeCategory.String, params: [{type: FieldTypeCategory.String}, {type: FieldTypeCategory.String}], hoist: false},
    // dyn_string = SubstrW(str, separator_str1, separator_str2) <- returns substring of str between separator_str1 and separator_str2
    {name: 'substr_between', returnType: FieldTypeCategory.String, params: [{type: FieldTypeCategory.String}, {type: FieldTypeCategory.String}, {type: FieldTypeCategory.String}], hoist: false},

    {name: 'bw', returnType: FieldTypeCategory.Boolean, params: [{type: FieldTypeCategory.Double}, {type: FieldTypeCategory.Double}, {type: FieldTypeCategory.Double}], hoist: false}, // Between: Bw(value, lower_bound, upper_bound inclusive)
    {name: 'os', returnType: FieldTypeCategory.Boolean, params: [{type: FieldTypeCategory.Double}, {type: FieldTypeCategory.Double}, {type: FieldTypeCategory.Double}], hoist: false}, // Outside = !Between: Os(value, lower_bound, upper_bound inclusive)

    {name: 'pct_change', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Double}, {type: FieldTypeCategory.Double}], hoist: false},
    {name: 'pct_rank_top', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Double}], hoist: true},
    {name: 'pct_rank_bottom', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Double}], hoist: true},
    {name: 'top_rank', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Double}], hoist: true},
    {name: 'bottom_rank', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Double}], hoist: true},
    {name: 'z_score', returnType: FieldTypeCategory.Double, params: [{type: FieldTypeCategory.Double}], hoist: true},
];
import _ from 'lodash';

import {Range, CompletionOption, Field} from './model';
import {FunctionDef} from './functions';
import { FieldTypeCategory } from '@thinkalpha/table-client';

export enum AstNodeType {
    functionCall = 'functionCall',
    string = 'string',
    number = 'number',
    column = 'column',
    binaryOperation = 'binaryOperation',
    unaryOperation = 'unaryOperation',
    paren = 'paren'
}

export interface ParserError {
    range?: Range;
    error: string;
}

export type AstNode = {
    // text: string;
    type: AstNodeType;
    dataType: FieldTypeCategory | null;
    range: Range;
    errors: ParserError[];
    valid: boolean;
    parent?: AstNode;
}

export type NodeWithDepth<T extends AstNode> = T & {
    depth: number;
}

export interface FunctionCallNode extends AstNode {
    type: AstNodeType.functionCall;
    functionDef: FunctionDef | null;
    functionName: string;
    functionNameRange: Range;
    openParenRange: Range;
    arguments: AstNode[] | null;
    argumentsRange: Range;
    argumentRanges: Range[];
    closeParenRange: Range | null;
}

export interface ParentheticalNode extends AstNode {
    type: AstNodeType.paren;
    content: AstNode | null;
    openParenRange: Range;
    contentRange: Range;
    closeParenRange: Range;
}

export interface NumberNode extends AstNode {
    type: AstNodeType.number;
    value: number | null;
}

export interface StringNode extends AstNode {
    type: AstNodeType.string;
    value: string | null;
    isRegex: boolean;
    isUnquoted: boolean;
    textRange: Range;
}

export interface ColumnNode extends AstNode {
    type: AstNodeType.column;
    name: string;
    sourceTable: string | null;
    field: Field | null;
}

export interface BinaryOperationNode extends AstNode {
    type: AstNodeType.binaryOperation;

    operand1: AstNode;
    operand1Range: Range;
    
    operator: string;
    operatorRange: Range;
    
    operand2: AstNode | null;
    operand2Range: Range;
}

export interface UnaryOperationNode extends AstNode {
    type: AstNodeType.unaryOperation;
    operator: string;
    operatorRange: Range;
    operand: AstNode | null;
    operandRange: Range;
}

export type KnownAstNode =
    | UnaryOperationNode
    | BinaryOperationNode
    | ColumnNode
    | StringNode
    | NumberNode
    | ParentheticalNode
    | FunctionCallNode;

export enum RangeType {
    unaryOperation = 'unaryOperation',
    operator = 'operator',
    operand = 'operand',
    
    binaryOperation = 'binaryOperation',

    column = 'column',

    string = 'string',
    text = 'text',

    number = 'number',

    parenthetical = 'parenthetical',
    openParen = 'openParen',
    content = 'content',
    closeParen = 'closeParen',

    functionCall = 'functionCall',
    functionName = 'functionName',
    arguments = 'arguments',
    argument = 'argument',
}

export interface RangeInfo {
    rangeType: RangeType;
    range: Range;
    source: AstNode;
    allowedType?: FieldTypeCategory;
    index?: number;
}

export function childrenOfNode(node: AstNode | null | undefined): AstNode[] {
    if (!node) return [];
    switch (node.type) {
        case AstNodeType.binaryOperation: {
            const n = node as BinaryOperationNode;
            return [n.operand1, ...(n.operand2 ? [n.operand2] : [])];//, ...childrenOfNode(n.operand1), ...childrenOfNode(n.operand2)];
        }
        case AstNodeType.column:
        case AstNodeType.number:
        case AstNodeType.string:
            return [];
        case AstNodeType.functionCall: {
            const n = node as FunctionCallNode;
            return [...(n.arguments || [])];//, ..._(n.arguments || []).flatMap(childrenOfNode).value()];
        }
        case AstNodeType.unaryOperation: {
            const n = node as UnaryOperationNode;
            return n.operand ? [n.operand] : [];//, ...childrenOfNode(n.operand)] : [];
        }
        case AstNodeType.paren: {
            const n = node as ParentheticalNode;
            return n.content ? [n.content] : [];//, ...childrenOfNode(n.content)] : [];
        }
        default:
            throw new Error(`Unknown AST node type: ${node.type}`);
    }
}

export function flattenAst(node: AstNode | null | undefined, depth = 0): ({depth: number} & AstNode)[] {
    if (!node) return [];
    return [{...node, depth}, ..._.flatMap(childrenOfNode(node), x => flattenAst(x, depth + 1))];
}
// import 'es6-shim';
// import 'url-search-params-polyfill';
import 'rheostat/initialize';
import 'rheostat/css/rheostat.css';

// tslint:disable-next-line:no-string-literal
window['alpha'] = true; // flag to indicate to index.html that we're loaded

if (Error.stackTraceLimit) {
    Error.stackTraceLimit = Infinity;
}

// services:
import {init as authInit} from './services/auth';
import './services/errorTracking';
import './services/table';
import './services/charting.ts';

import React from 'react';
import ReactDOM from 'react-dom';

import { AppContainer } from 'react-hot-loader';

import {App} from './app';
import { appConfig } from './config/appConfig';

// // @ts-ignore
// import {registerObserver} from 'react-perf-devtool';
import { filter } from 'rxjs/operators';

import './styles/index.scss';

// import highcharts from 'highcharts';
// import highchartsTheme from 'highcharts/themes/dark-unica';
// highchartsTheme(highcharts);

import loglevel from 'loglevel';
loglevel.enableAll();
loglevel.setDefaultLevel(loglevel.levels.DEBUG);
loglevel.getLogger('table-client').setLevel(loglevel.levels.INFO);

async function init() {
    await authInit(); // puts access token in cache

    const rootEl = document.getElementsByTagName('alpha').item(0);
    const render = (Component: React.ComponentType<any>) => {
        ReactDOM.render(<AppContainer errorReporter={({ error }) => { throw error; }}><Component/></AppContainer>, rootEl);
    };
    render(App);

    // Hot Module Replacement API
    if ((module as any).hot) {
        (module as any).hot.accept('./app', () => {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const required = require('./app');
            const NextApp = required.App;
            render(NextApp);
        });
    }

    // if ('serviceWorker' in navigator) {
    //     window.addEventListener('load', () => {
    //         navigator.serviceWorker.register('/sw.js').then(function(registration) {
    //             // Registration was successful
    //             log.info('ServiceWorker registration successful with scope: ', registration.scope);
    //         }, function(err) {
    //             // registration failed :(
    //             log.error('ServiceWorker registration failed: ', err);
    //         });
    //     });

    //     window.addEventListener('beforeinstallprompt', (e: BeforeInstallPromptEvent) => {
    //         e.preventDefault();
    //         e.prompt();
    //     });
    // }
}

init();

import localforage from 'localforage';
window['localforage'] = localforage;
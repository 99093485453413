import { FilterSymbol } from './symbols';
import { FieldTypeCategory, FieldType } from '@thinkalpha/table-client';
import { AstNode } from './ast';

export type Range = {start: number, end: number};

export enum HistoricType {
    none = 'none',
    bars = 'bars',
    history = 'history'
}

export enum CompletionType {
    field = 'field',
    function = 'function',
    syntax = 'syntax'
}

export interface Section {
    source?: Token;
    text: string;
    errors: string[];
    class?: string;
    range: Range;
    node: AstNode | undefined;
}

export enum TokenType {
    Error = 'error',
    Symbol = 'symbol',
    String = 'string',
    Regex = 'regex',
    Name = 'name',
    Number = 'number',
    Whitespace = 'ws'
}

export interface Token {
    range: Range;
    token: string | FilterSymbol;
    type: TokenType;
    error?: string;
}

export interface Field {
    name: string;
    type: FieldTypeCategory;
    description?: string;
    sourceTable: string | undefined;
    historic: HistoricType;
}

export interface CompletionOption {
    type: CompletionType;
    displayText?: string;
    text: string;
    source?: string;
    description?: string;
    dataType: FieldTypeCategory;
    historic: boolean;
};

export interface FunctionContext {
    name: string;
    params?: {
        name?: string;
        description?: string;
        dataType: FieldTypeCategory;
        optional: boolean;
    }[];
    paramIndex?: number;
    returnType?: FieldTypeCategory;
};

export interface CompletionContext {
    index: number;
    text: string;
    options: CompletionOption[];
    functionContext?: FunctionContext;
}
import loglevel from 'loglevel';
const log = loglevel.getLogger('if-then-definer');

import { LogicalOperator, Operand, Operator, IfThenGroupModel, IfThenLineModel } from '../../../if-then/model';

import { UniverseCreatorResult } from '../../../universe/model';
import { IfThenStrategy, StrategyCreatorResult, StrategyType } from '../../model';

import { DefinitionBundle, FieldDescriptor, FieldType, FieldTypeCategory, ObjectDefinition, TypesInTypeCategory, Formula, TableClient, RawClient } from '@thinkalpha/table-client';
import { randomString } from '../../../util/randomString';

import { defaultColumns } from '../defaultCols';

import lexer from '../../../components/filter-editor/lexer';
import parser from '../../../components/filter-editor/parser.ast';
import compiler, { HoistedColumn } from '../../../components/filter-editor/compiler';

import { StrategyDefiner } from '../../definer';
// import {strategyFields} from '../../../if-then/fields.hardcoded';
import { Field, HistoricType } from '../../../components/filter-editor/model';
import {pointInTimeToSubscript, timeframeToSubscript} from '../../../components/timeframe/pointInTime';
import { CustomFormula } from '../../../components/data-builder/model';

import {isMoment} from 'moment';
import { strategyFields, allStrategyFields } from '../../../if-then/fields.hardcoded';

const stringFieldTypes = [...TypesInTypeCategory.get(FieldTypeCategory.String)!, FieldTypeCategory.String];
Object.seal(stringFieldTypes);

function parse(operand: Operand, fields: readonly Field[]) {
    const tokens = lexer(operand) || [];
    const res = parser(tokens, fields);
    return res;
}

function lineToFilter(line: IfThenLineModel, fields: readonly Field[], hoists: HoistedColumn[]): string | undefined {
    const operand1 = line.operand1;
    const operand2 = line.operand2;

    const operand1Parsed = parse(operand1, fields);
    const operand2Parsed = parse(operand2, fields);
    const compiledOperand1 = compiler(operand1Parsed.root, {hoist: true});
    const compiledOperand2 = compiler(operand2Parsed.root, {hoist: true});

    hoists.push(...compiledOperand1.hoistedColumns, ...compiledOperand2.hoistedColumns);

    const combined = `${compiledOperand1.result} ${Operator[line.operator]} ${compiledOperand2.result}`;
    return combined;
}

function groupToFilter(strategy: IfThenGroupModel, fields: readonly Field[], hoists: HoistedColumn[]): string | undefined {
    if (!strategy.lines.length) return undefined;
    if (!strategy.operator) return undefined;

    const pieces = strategy.lines
        .filter(x => x.enabled)
        .map(line => {
            if (line.type as any === 'strategy') line.type = 'group'; // hack to preserve saved legacy strategies
            if (line.type === 'group') {
                return groupToFilter(line, fields, hoists);
            } else if ('operator' in line) { // not a placeholder
                return lineToFilter(line, fields, hoists);
            } else {
                // console.log('ignoring placeholder line', line);
            }
        })
        .filter(x => x)
        .map(x => `(${x})`);

    return pieces.filter(x => x).join(` ${LogicalOperator[strategy.operator]} `);
}

// const strategyViewMoniker = 'strategy';
const cleanViewMoniker = 'clean';

function hoistToFormula(hoist: HoistedColumn): Formula {
    return {
        formula: hoist.formula,
        name: hoist.name,
        desc: 'An automatically created column to hoist a function'
    };
}

function modelToFormulas(f: CustomFormula, fields: readonly Field[]): Formula[] {
    const parseResult = parse(f.formula, fields);
    const compiled = compiler(parseResult.root, {hoist: true, barLength: f.timeframe && f.timeframe.barLength});
    let functionName = f.function;
    if (functionName) {
        if (!functionName.startsWith('hist_') && f.timeframe && isMoment(f.timeframe.start) !== isMoment(f.timeframe.end)) {
            functionName += '_since';
        }
    }
    const formula: Formula = {
        name: f.name,
        desc: f.description,
        subscript: f.timeframe && timeframeToSubscript(f.timeframe),
        formula: `${functionName || ''}(${compiled.result})`,
        match_key: {
            primary: [
                {
                    'on_left_side': 'symbol',
                    'on_right_side': 'symbol',
                    'venue': '*'
                }
            ]
        }
    };

    return [...compiled.hoistedColumns.map(hoistToFormula), formula];
}

export const createIfThenStrategy: StrategyDefiner<IfThenStrategy> = (universeTableName, strategy) => {
    let intermediateName = randomString();
    const strategyViewName = randomString();

    const name = randomString();

    const formulas: Formula[] = [];
    const fields = [...allStrategyFields];
    for (const formula of strategy.formulas) {
        formulas.push(...modelToFormulas(formula, fields));
        fields.push({
            description: formula.description,
            name: formula.name,
            sourceTable: formula.sourceTable,
            type: formula.type,
            historic: HistoricType.none
        });
    }

    const hoists: HoistedColumn[] = [];
    const filter = groupToFilter(strategy.root, fields, hoists);

    formulas.push(...hoists.map(hoistToFormula));

    const definitions: DefinitionBundle = [];

    if (universeTableName === 'EquityUniverse' && !formulas.length && !hoists.length) {
        intermediateName = 'allUniverseIfThenBase';
    } else {
        const firstTableName = randomString();
        const baseDefinition = {
            table: {
                name: firstTableName,
                auto_sources: [
                    {
                        name: universeTableName,
                        columns: [
                            // 'OfficialPrice'
                        ]
                    },
                    {
                        name: 'allUniverse',
                        columns: ['.*'],
                        match_key: {
                            primary: [
                                {
                                    on_left_side: 'symbol',
                                    on_right_side: 'symbol',
                                    // venue: '*'
                                }
                            ]
                        }
                    }
                ],
                row_filter: {
                    match_by: 'fullkey',
                    pattern: '.*'
                },
                formulas: [
                    ...formulas,
                    {
                        name: 'Symbol',
                        formula: 'ticker'
                    }
                ]
            }
        };
        definitions.push(baseDefinition);

        const validDataFilter: ObjectDefinition = {
            views: [
                {
                    table: firstTableName,
                    name: cleanViewMoniker,
                    filter: 'Bid_Price > 0 and Ask_Price < 200000 and Last_Price > 0'
                }
            ]
        };
        definitions.push(validDataFilter);

        const intermediateTable: ObjectDefinition = {
            table: {
                name: intermediateName,
                auto_sources: [
                    {
                        name: firstTableName,
                        view: cleanViewMoniker,
                        columns: ['.*'],
                        match_key: {
                            primary: [
                                {
                                    on_left_side: 'symbol',
                                    on_right_side: 'symbol',
                                    // venue: '*'
                                }
                            ]
                        }
                    }
                ]
            }
        };
        definitions.push(intermediateTable);
    }

    if (!filter) return {definitions, name: intermediateName};

    definitions.push(
        { views: [{
            table: intermediateName,
            name: strategyViewName,
            filter
        }]},
        { table: {
            name,
            auto_sources: [
                {
                    name: intermediateName,
                    view: strategyViewName,
                    columns: ['.*'],
                    match_key: {
                        primary: [
                            {
                                on_left_side: 'symbol',
                                on_right_side: 'symbol',
                                // venue: '*'
                            }
                        ]
                    }
                }
            ],
            formulas: [   
            ]
        }}
    );

    return {definitions, name};
};
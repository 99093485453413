import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useInputCallback } from '../../hooks/useInputState';
import _ from 'lodash';
import { AstNode, flattenAst } from './ast';
import {debounceTime} from 'rxjs/operators';
import { Subject } from 'rxjs';
import lexer from './lexer';
import parser, { ParserResult } from './parser.ast';
import {getRanges} from './ranges';
import {Range, Token} from './model';
import { saveSelection } from '../../util/selections';
import { overlappingRange } from '../../util/overlappingRange';
import completer from './completer';
import { Tabs, Tab } from '@material-ui/core';

import JSONTree from 'react-json-tree';

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

export const ParserDemoPage: React.FC = ({}) => {
    const [value, setValue] = useState('');
    const [parserResult, setParserResult] = useState<ParserResult>();
    const inputRef = useRef<HTMLInputElement>(null);
    const [range, setRange] = useState<Range>();

    const fields = [];
    
    const [activeTab, setActiveTab] = useState('Cursor node');

    const allNodes = useMemo(() => {
        if (!parserResult) return undefined;
        if (!parserResult.root) return undefined;
        const allNodes = flattenAst(parserResult.root);
        return allNodes;
    }, [parserResult]);

    const tokens = useMemo(() => lexer(value), [value]);

    const currentToken = useMemo(() => {
        if (!tokens) return undefined;
        if (!range) return undefined;
        const currentTokens = tokens.filter(tk => overlappingRange(tk.range, range));
        const currentToken = currentTokens[currentTokens.length - 1];
        return currentToken;
    }, [range, tokens]);

    const completion = useMemo(() => {
        if (!range) return;
        if (!parserResult) return;

        return completer(value, tokens, range.end,
            parserResult.text, parserResult.root, range.end,
            fields);
    }, [fields, range, parserResult, tokens, value]);

    const ranges = useMemo(() => {
        if (!parserResult) return [];
        return getRanges(parserResult.root, undefined);
    }, [parserResult]);

    const cursorNode = useMemo(() => {
        if (!range) return undefined;
        return _(allNodes)
            .filter(x => overlappingRange(x.range, {start: range.start - 1, end: range.end}))
            .minBy(x => x.range.end - x.range.start);
    }, [range, allNodes]);

    const updateRange = useCallback(() => {
        const range = inputRef.current!.selectionStart === null ? undefined : {start: inputRef.current!.selectionStart!, end: inputRef.current!.selectionEnd! };
        setRange(range);
    }, []);

    const onValueChanged = useInputCallback(input => {
        setValue(input);
        updateRange();
    }, []);

    useEffect(() => {
        const handle = setTimeout(() => {
            const result = parser(tokens, []);
            setParserResult(result);
        }, 500);

        return () => clearTimeout(handle);
    }, [tokens]);

    const switchToTab = useCallback((_, tab: string) => {
        setActiveTab(tab);
    }, []);

    return <div>
        <input value={value} spellCheck={false} ref={inputRef} onKeyUp={updateRange} onClick={updateRange} onChange={onValueChanged} />
        <div><strong>Current range: </strong>{JSON.stringify(range)}</div>
        <Tabs color="primary" variant="fullWidth" value={activeTab} onChange={switchToTab} scrollButtons="auto">
            <Tab value="Cursor node" label="Cursor node"/>
            <Tab value="Cursor range(s)" label="Cursor range(s)"/>
            <Tab value="Prev node" label="Prev node"/>
            <Tab value="Current node" label="Current node"/>
            <Tab value="Root node" label="Root node"/>
            <Tab value="Tokens" label="Tokens"/>
            <Tab value="Ranges" label="Ranges"/>
            <Tab value="Completion" label="Completion"/>
        </Tabs>
        <div hidden={activeTab !== 'Cursor node'}><h3>Cursor node</h3><pre><JSONTree data={cursorNode}/>{JSON.stringify(cursorNode, getCircularReplacer(), 2)}</pre></div>
        <div hidden={activeTab !== 'Cursor range(s)'}><h3>Cursor range(s)</h3>{range && <JSONTree data={ranges.filter(x => overlappingRange(x.range, range))}/>}<pre>{range && JSON.stringify(ranges.filter(x => overlappingRange(x.range, range)), getCircularReplacer(), 2)}</pre></div>
        {/* <div hidden={activeTab !== 'Prev node'}><h3>Prev node</h3><pre><JSONTree data={prevNode}/>{JSON.stringify(prevNode, getCircularReplacer(), 2)}</pre></div> */}
        {/* <div hidden={activeTab !== 'Current node'}><h3>Current node</h3><pre><JSONTree data={currentNode}/>{JSON.stringify(currentNode, getCircularReplacer(), 2)}</pre></div> */}
        <div hidden={activeTab !== 'Root node'}><h3>Root node</h3><pre><JSONTree data={parserResult && parserResult.root}/>{JSON.stringify(parserResult && parserResult.root, getCircularReplacer(), 2)}</pre></div>
        <div hidden={activeTab !== 'Tokens'}><h3>Tokens</h3><pre><JSONTree data={tokens}/>{JSON.stringify(tokens, getCircularReplacer(), 2)}</pre></div>
        <div hidden={activeTab !== 'Ranges'}><h3>Ranges</h3><pre><JSONTree data={ranges}/>{JSON.stringify(ranges, getCircularReplacer(), 2)}</pre></div>
        <div hidden={activeTab !== 'Completion'}>{range && <><h3>Completion</h3><pre>
            {/* <JSONTree data={[value, range.end, tokens,
            root, range.end,
            fields]}/> */}
            <JSONTree data={completion}/>{JSON.stringify(completion, getCircularReplacer(), 2)}</pre></>}</div>
    </div>;
};
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export function useInputState<T extends string = string>(initialValue?: T): [T, Dispatch<SetStateAction<string>>, (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void] {
    const [ state, setState ] = useState<T>(initialValue || ('' as T));
    const callback = useCallback(({target: {value}}: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setState(value as T);
    }, [setState]);
    return [state as T, setState, callback];
}

export function useNumberInputState(initialValue?: number | null): [number | null, Dispatch<SetStateAction<number | null>>, (e: React.ChangeEvent<HTMLInputElement>) => void] {
    const [ state, setState ] = useState<number | null>(null);
    const callback = useCallback(({target: {value, valueAsNumber}}: React.ChangeEvent<HTMLInputElement>) => {
        setState(value ? valueAsNumber : null);
    }, [setState]);
    return [state, setState, callback];
}

export function useCheckedInputState(initialValue?: boolean): [boolean, Dispatch<SetStateAction<boolean>>, (e: React.ChangeEvent<HTMLInputElement | HTMLOptionElement>) => void] {
    const [ state, setState ] = useState<boolean>(initialValue || false);
    const callback = useCallback(({target: {checked}}: React.ChangeEvent<HTMLInputElement>) => {
        setState(checked);
    }, [setState]);
    return [state, setState, callback];
}

export function useStringBooleanInputState(initialValue?: boolean): [boolean, Dispatch<SetStateAction<boolean>>, (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void] {
    const [ state, setState ] = useState<boolean>(initialValue || false);
    const callback = useCallback(({target: {value}}: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setState(value === 'true');
    }, [setState]);
    return [state, setState, callback];
}

export function useInputCallback<T extends string = string>(callback: (value: T) => void, deps?: any[]) {
    const wrapped = useCallback(({target: {value}}: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        callback(value as T);
    }, [callback]);

    return wrapped;
}

export function useMultipleSelectCallback<T extends string[] = string[]>(callback: (value: T) => void, deps?: any[]) {
    const wrapped = useCallback(({target: {value}}: React.ChangeEvent<{ name?: string, value: string[] }>) => {
        callback(value as T);
    }, [callback]);

    return wrapped;
}

export function useNumberInputCallback(callback: (value: number | null) => void, deps?: any[]) {
    const wrapped = useCallback(({target: {value, valueAsNumber}}: React.ChangeEvent<HTMLInputElement>) => {
        callback(value ? valueAsNumber : null);
    }, [callback]);

    return wrapped;
}

export function useCheckedInputCallback(callback: (value: boolean) => void, deps?: any[]) {
    const wrapped = useCallback(({target: {checked}}: React.ChangeEvent<HTMLInputElement>) => {
        callback(checked);
    }, [callback]);

    return wrapped;
}
import { SortModel } from '@thinkalpha/table-client';

// this is currently the same as react-grid-layout's Layout component, but no guarantees that it will stay the same
export interface Layout {
    /**
     * X position in grid units.
     */
    x: number;

    /**
     * Y position in grid units.
     */
    y: number;

    /**
     * Width in grid units.
     */
    w: number;

    /**
     * Height in grid units.
     */
    h: number;

    /**
     * Minimum width in grid units.
     */
    minW?: number;

    /**
     * Maximum width in grid units.
     */
    maxW?: number;

    /**
     * Minimum height in grid units.
     */
    minH?: number;

    /**
     * Maximum height in grid units.
     */
    maxH?: number;

    /**
     * set by DragEvents (onDragStart, onDrag, onDragStop) and ResizeEvents (onResizeStart, onResize, onResizeStop)
     */
    moved?: boolean;

    /**
     * If true, equal to `isDraggable: false` and `isResizable: false`.
     */
    static?: boolean;

    /**
     * If false, will not be draggable. Overrides `static`.
     */
    isDraggable?: boolean;

    /**
     * If false, will not be resizable. Overrides `static`.
     */
    isResizable?: boolean;
}

export enum WidgetType {
    strategy = 'strategy',
    graph = 'graph',
}
export const WidgetTypes: readonly WidgetType[] = Object.keys(WidgetType).map(x => WidgetType[x] as WidgetType);
Object.seal(WidgetTypes);
export const WidgetTypeNames: ReadonlyMap<WidgetType, string> = new Map([
    [WidgetType.strategy, 'Results View'],
    [WidgetType.graph, 'Chart'],
]);
Object.seal(WidgetTypeNames);

export interface Widget<TType extends WidgetType = WidgetType, TModel extends WidgetModel = WidgetModel> {
    id: string;
    layout: Layout;
    type: TType;
    model: TModel | undefined;
}

export interface Workspace {
    id: string;
    name: string;
    widgets: KnownWidget[];
}

export type KnownWidget =
    | Widget<WidgetType.strategy, StrategyWidgetModel>
    | Widget<WidgetType.graph, GraphModel>
;

export interface WidgetModel {
    // type: WidgetType;
}

export interface StrategyWidgetModel extends WidgetModel {
    // type: WidgetType.strategy;
    strategyId: string;
}

export enum GraphType {
    line = 'line',
    bar = 'bar',
    pie = 'pie',
    scatter = 'scatter',
    // bubble = 'bubble',
    // box = 'box'
}

export interface GraphModel extends WidgetModel {
    strategyId: string;
    sort: SortModel;
    settings: KnownGraphSettings;
}

export interface GraphSettings {
    graphType: GraphType;
}

export interface BarChartSettings extends GraphSettings {
    graphType: GraphType.bar;

    categoryDimension: string;
    magnitudeDimension: string;
    widthDimension?: string;
}

export interface LineOrScatterChartSettings extends GraphSettings {
    graphType: GraphType.line | GraphType.scatter;

    xDimension: string;
    yDimensions: string[];

    showBellCurve: boolean;
}

export interface PieChartSettings extends GraphSettings {
    graphType: GraphType.pie;

    categoryDimension: string;
    magnitudeDimension: string;
}

export type KnownGraphSettings =
    | BarChartSettings
    | LineOrScatterChartSettings
    | PieChartSettings
;
import {useEffect, EffectCallback, DependencyList, useRef, useState} from 'react';
import _ from 'lodash';

export function useDeepEffect(effect: EffectCallback, deps?: DependencyList) {
    const [cachedDeps, setCachedDeps] = useState<DependencyList>();
    useEffect(() => {
        if (_.isEqual(deps, cachedDeps)) return;
        // console.log('unequal deps and cached deps', deps, cachedDeps);
        setCachedDeps(deps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => effect(), [cachedDeps]);
}
import './anchor.scss';

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import moment, { Moment } from 'moment';
import { SlidingWindow, PointInTime as PointInTimeModel, AbsolutePointInTime, PeriodTypes, PeriodType, Timeframe } from './model';
import { useStringBooleanInputState, useInputState, useNumberInputState, useNumberInputCallback, useCheckedInputCallback } from '../../hooks/useInputState';
import { useDeepEffect } from '../../hooks/useDeepEffect';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { RadioGroup, FormControlLabel, Radio, Select, MenuItem, TextField, Checkbox } from '@material-ui/core';
import { SlidingWindowComponent } from './sliding';
import DatePicker from 'react-datepicker';
import { AbsolutePointInTimeComponent } from './absolute';
import { useModelCommitter } from '../../hooks/useModelCommitter';
import { useToggleState } from '../../hooks/useToggleState';

export const Anchor: React.FC<{model: Moment | undefined, windows: SlidingWindow[], onChange: (model?: Moment) => void}> = ({model, windows, onChange}) => {
    const periodicities = useMemo(() => !windows ? [] : windows.map(p => p && p.period), [windows]);
    const periodicitySet = useMemo(() => new Set(periodicities.filter(p => !!p)), [periodicities]);

    const onAnchorChanged = useCallback((date: Date) => onChange(moment(date)), [onChange]);

    const [active, setActive] = useToggleState();
    const [timePart, setTimePart] = useState<Moment | undefined>(moment().startOf('year').set({h: 9, m: 30}));
    const [datePart, setDatePart] = useState<Moment | undefined>(moment().startOf('year'));

    const onTimePartChanged = useCallback((dt: Moment | Date | null | undefined) => {
        dt = dt ? moment(dt) : undefined;
        setTimePart(dt);
    }, []);
    const onDatePartChanged = useCallback((dt: Moment | Date | null | undefined) => {
        dt = dt ? moment(dt) : undefined;
        setDatePart(dt);
    }, []);
    const onAnchorDayChanged = useNumberInputCallback(day => {
        setDatePart(dt => dt && dt.clone().set('day', day || 0));
    }, []);

    useModelCommitter(
        model,
        onChange,
        {timePart, datePart},
        ({timePart, datePart}) => {
            if (!active) return undefined;
            if (!timePart || !datePart) return false;
            const {hours, minutes, seconds} = timePart.toObject();
            return datePart.clone().set({hours, minutes, seconds});
        },
        model => {
            if (!model) {
                setActive(false);
                setTimePart(moment().startOf('year').set({h: 9, m: 30}));
                setDatePart(moment().startOf('year'));
            } else {
                setActive(true);
                setTimePart(model);
                setDatePart(model);
            }
        }
    );

    const onActiveChanged = useCheckedInputCallback(setActive);
    
    // useEffect(() => {
    //     const validPeriods = periods.filter((x): x is SlidingWindow => x !== undefined && x.period && x.value !== undefined);
    //     if (validPeriods.length <= 0) onChange(undefined);
    //     // if (anchored && !anchor) onChange(undefined);
    //     const newModel: RelativeTimeframeModel = {
    //         periods: validPeriods,
    //         anchor: anchored ? anchor : undefined
    //     };
    //     if (!_.isEqual(newModel, value)) onChange(newModel);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [periods, anchored, anchor]);

    // useEffect(() => {
    //     if (!value) {
    //         const invalids = periods.filter(x => !x || !x.period || x.value === undefined);
    //         if (!invalids.length) invalids.push(undefined);
    //         setPeriodsDedup(periods => periods.find(x => x && x.period && x.value) ? invalids : periods);
    //         setAnchored(anchored && anchor ? anchored : false);
    //     } else {
    //         const invalids = periods.filter(x => !x || !x.period || x.value === undefined);
    //         const incoming = value.periods;
    //         const all = [...incoming, ...invalids];
    //         if (all.length === 0) all.push(undefined);
    //         setPeriodsDedup(all);
    //         setAnchorDedup(value.anchor);
    //         setAnchored(existing => value.anchor ? true : !anchor ? existing : false);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [value]);

    const periodicityIndex = windows && Math.min(...windows.filter(x => x && x.period).map(p => PeriodTypes.indexOf(p!.period)));
    useEffect(() => {
        // setAnchorDedup(undefined);
    }, [periodicityIndex]);

    let anchorSelector: React.ReactNode;
    let anchorTerm = '';
    // if (periodicitySet.has(PeriodType.minute)) {
    //     anchorSelector = <TextField
    //         InputProps={{inputProps: {min: 0, max: 59}}}
    //         type="number"
    //         value={model ? model.seconds() : ''}
    //         onChange={onAnchorSecondsChanged}
    //         label="Seconds of Minute"
    //     />;
    // } else if (periodicitySet.has(PeriodType.hour)) {
    //     anchorSelector = <TextField
    //         InputProps={{inputProps: {min: 0, max: 59}}}
    //         value={model ? model.minutes() : ''}
    //         type="number"
    //         onChange={onAnchorMinutesChanged}
    //         label="Minute of Hour"
    //     />;
    /*} else */if (periodicitySet.has(PeriodType.day)) {
        anchorTerm = 'at';
        anchorSelector = <KeyboardTimePicker
            defaultValue={undefined}
            disabled={!active}
            InputLabelProps={{
                shrink: true,
            }}
            minutesStep={5}
            onChange={onTimePartChanged}
            value={timePart || null}
        />;
    } else if (periodicitySet.has(PeriodType.week)) {
        // no-op
    } else if (periodicitySet.has(PeriodType.month)) {
        anchorTerm = 'on day';
        anchorSelector = <>
            <TextField
                type="number"
                onChange={onAnchorDayChanged}
                value={datePart ? datePart.day() : null}
                inputProps={{step: 1, min: 1, max: 31}}
                disabled={!active}
            />
            &nbsp;at&nbsp;
            <KeyboardTimePicker
                disabled={!active}
                // label="Time of Day"
                InputLabelProps={{
                    shrink: true,
                }}
                minutesStep={5}
                onChange={onTimePartChanged}
                value={timePart || null}
            />
        </>;
    } else if (periodicitySet.has(PeriodType.quarter)) {
        // no-op
    } else if (periodicitySet.has(PeriodType.year)) {
        anchorTerm = 'on';
        anchorSelector = <>
            <DatePicker
                disabled={!active}
                openToDate={new Date(0, 0, 1, 0, 0, 0)}
                minDate={new Date(0, 0, 1, 0, 0, 0)}
                maxDate={new Date(0, 11, 31, 23, 59, 59)}
                selected={datePart && datePart.isValid() ? datePart.toDate() : null}
                onChange={onDatePartChanged}
                showTimeSelect={false}
                popperPlacement="left-start"
                renderCustomHeader={args => <div className="month-and-day-header">
                    <button onClick={args.decreaseMonth} disabled={args.prevMonthButtonDisabled}><i className="fas fa-caret-left" /></button>
                    {args.date.toLocaleString('default', { month: 'long' })}
                    <button onClick={args.increaseMonth} disabled={args.nextMonthButtonDisabled}><i className="fas fa-caret-right" /></button>
                </div>}
                dateFormat="MMM Do"
            />
            &nbsp;&nbsp;at&nbsp;&nbsp;
            <KeyboardTimePicker
                disabled={!active}
                // label="Time of Day"
                InputLabelProps={{
                    shrink: true,
                }}
                minutesStep={5}
                onChange={onTimePartChanged}
                value={timePart || null}
            />
        </>;
    }

    return <div className="anchor time-picker" hidden={!windows.length || !anchorSelector}>
        <FormControlLabel control={<Checkbox/>} checked={active} onChange={onActiveChanged} label={anchorTerm} /> {anchorSelector}
    </div>;
};
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';

import { FieldDescriptor, FieldTypeCategory, TypesInTypeCategory } from '@thinkalpha/table-client';
import {Subject, timer} from 'rxjs';
import {debounce, tap} from 'rxjs/operators';

import { useSubscription } from '../hooks/useSubscription';
import lexer from './filter-editor/lexer';
import parser from './filter-editor/parser.ast';
import { descriptorToField } from './data-builder/data-builder';
import compile from './filter-editor/compiler';
import { flattenAst } from './filter-editor/ast';

export function smartFilterToTableFilter(text: string, fields: FieldDescriptor[] | undefined): string | undefined {
    if (!text) {
        return undefined;
    } else if (text.startsWith('=')) {
        // construct a "complex" filter: verbatum text without the =
        const tokens = lexer(text.substr(1));
        const parsed = parser(tokens, (fields || []).map(descriptorToField));
        const valid = parsed.valid && flattenAst(parsed.root).every(x => x.valid);
        if (!valid) return undefined;
        if (parsed.root!.dataType !== FieldTypeCategory.Boolean) return undefined;

        const compiled = compile(parsed.root, {hoist: false}).result;

        return compiled;
    } else if (!fields) {
        return undefined;
    } else {
        // construct a "simple" filter: make a really complex filter to search the specified text
        const types = TypesInTypeCategory.get(FieldTypeCategory.String)!;
        const pieces = fields.filter(x => types.indexOf(x.type) !== -1).map(x => `(${x.name} like '%.*${text}.*')`);
        return pieces.join(' or ');
    }
}

// export default SmartFilterBox;
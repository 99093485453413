import React, { useCallback, useState, useEffect, useRef } from 'react';
import './sortModelInput.scss';

import { FieldDescriptor, FieldTypeCategoryNames, FieldTypeCategory, SortModel, SortOrder } from '@thinkalpha/table-client';
import { FieldSelector, MultipleFieldSelector } from '../../../components/field-selector/field-selector';
import { useModelCommitter } from '../../../hooks/useModelCommitter';
import { TextField, MenuItem, IconButton } from '@material-ui/core';
import { DndProvider, useDrag, useDrop, DropTargetMonitor, XYCoord } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import classNames from 'classnames';

interface Props {
    fields: FieldDescriptor[];
    sort: SortModel;
    onChange: (model: SortModel) => void;
}

interface SortOptionProps {
    name: string;
    sortOrder: SortOrder;
    index: number;
    changeOrder: (name: string, order: SortOrder) => void;
    removeOption: (name: string) => void;
    changeOptionOrder: (i1: number, i2: number) => void;
}

interface DragItem {
    name: string;
    type: string;
    index: number;
}

const SORT_OPTION_TYPE = 'sort_option';

const SortOption: React.FC<SortOptionProps> = ({name, sortOrder, index, changeOrder, changeOptionOrder, removeOption}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [, drop] = useDrop({
        accept: SORT_OPTION_TYPE,
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current!.getBoundingClientRect();

            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();

            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;


            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            changeOptionOrder(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: SORT_OPTION_TYPE, name, index },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div ref={ref} className={classNames({'sort-option': true, 'dragging': isDragging})} >
            <div className="name">{name}</div>
            <TextField
                select 
                variant="outlined" 
                className="type-select" 
                onChange={e => changeOrder(name, (e.target.value as SortOrder))}
                value={sortOrder}>
                <MenuItem value={SortOrder.ascending}>Ascending</MenuItem>
                <MenuItem value={SortOrder.descending}>Descending</MenuItem>
            </TextField>
            <IconButton size="small" className="close" onClick={() => removeOption(name)}><i className="fas fa-times-circle"/></IconButton> 
        </div>
    );
};

export const SortModelInput: React.FC<Props> = ({fields, sort, onChange}) => {

    const addOption = useCallback((option) => {
        onChange({
            ...sort,
            [option]: SortOrder.ascending
        });
    }, [sort, onChange]);

    const changeOrder = useCallback((option, order) => {
        onChange({
            ...sort,
            [option]: order
        });
    }, [sort, onChange]);

    const removeOption = useCallback((option) => {
        const {[option]: omit, ...remaining} = sort;
        onChange(remaining);
    }, [sort, onChange]);

    const changeOptionOrder = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const keys = Object.keys(sort);
            const ordered = [...keys];
            ordered[dragIndex] = keys[hoverIndex];
            ordered[hoverIndex] = keys[dragIndex];

            const updated = ordered.reduce((ac, curr) => ({...ac, [curr]: sort[curr]}), {});
            onChange(updated);
        },
        [sort, onChange],
    );


    const sortFields = Object.keys(sort);
    const availableFields = fields.filter(field => !sortFields.includes(field.name));

    return <div className="sortModelInput">
        <div className="title">Sorting options</div>
        <FieldSelector label="Add sort option" fields={availableFields} value={''} onChange={addOption} />
        <DndProvider backend={Backend}>
            <div className="list">
                {sortFields.map((option, index) => <SortOption 
                    key={option}
                    name={option}
                    index={index}
                    sortOrder={sort[option]}
                    removeOption={removeOption}
                    changeOrder={changeOrder}
                    changeOptionOrder={changeOptionOrder}
                />)}
            </div>
        </DndProvider>
    </div>;
};

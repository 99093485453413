import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { boundMethod } from 'autobind-decorator';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'reactive-state/react';

import {NewRiskScreen as NewRiskScreenContract, RiskScreen as RiskScreenContract, TableState as TableStateContract } from '../contracts';

import Split from 'react-split';

import loglevel from 'loglevel';
const logger = loglevel.getLogger('risk-view');

import classnames from 'classnames';
import numeral from 'numeral';

import { fromEvent, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { FieldDescriptor, Key } from '@thinkalpha/table-client';
import {upsertRiskScreen, getRiskScreenByKey} from '../services/risk-screens';
import queryString from 'query-string';

import jwt_decode from 'jwt-decode';

import {
    ColumnPreference,
    ContextMenuItemsCreator,
    MenuItem,
    TableUserData
} from '../components/table-view/model';
import RiskPopout, {open as openPopout} from './risk-popout';
import RiskTable, { RiskTableSaveData } from './risk-table';

import { Dictionary } from '../util/dictionary';
import './risk.scss';
import { Tabs, Tab } from '@material-ui/core';
import { Store } from 'reactive-state';
import { AppState } from '../state';
import { useToggleState } from '../hooks/useToggleState';
import { isNumber } from 'lodash';

type Props = {
    showAdamHack: boolean;
    username: string;
    prefix?: string;
};

const userToKeyPrefix = new Map<string, string>([
    ['bkehoe', 'L1'],
    ['jshtul', 'L1'],
    ['msamue', 'L2'],
    ['pfiede', 'L1'],
]);

export function globalPreferences(field: FieldDescriptor): ColumnPreference {
    let pref: ColumnPreference | undefined;
    if (field.name.indexOf('Key') !== -1) {
        pref = { name: field.name, ...pref, hidden: true };
    }
    // if (field.name.endsWith('Exposure')) {
    //     pref = { name: field.name, ...pref, flashOnChange: false };
    // }
    if (field.name.startsWith('Total')) {
        pref = {
            name: field.name,
            ...pref,
            display: field.name.substr('Total'.length)
        };
    }
    pref = {
        name: field.name,
        ...pref,
        flashOnChange: false
    };
    return pref;
}

const RiskPage: React.FC<Props & RouteComponentProps<{key: string}>> = ({match, history, showAdamHack, username, prefix = 'All'}) => {
    const [platformsTableState, setPlatformsTableState] = useState<RiskTableSaveData>();
    const [accountsTableState, setAccountsTableState] = useState<RiskTableSaveData>();
    const [symbolsTableState, setSymbolsTableState] = useState<RiskTableSaveData>();
    const [tradersTableState, setTradersTableState] = useState<RiskTableSaveData>();
    const [positionsTableState, setPositionsTableState] = useState<RiskTableSaveData>();
    const [adamsAccountsTableState, setAdamsAccountsTableState] = useState<RiskTableSaveData>();

    const [accountsFilter, setAccountsFilter] = useState<string>();
    const [symbolsFilter, setSymbolsFilter] = useState<string>();
    const [tradersFilter, setTradersFilter] = useState<string>();
    const [positionsFilter, setPositionsFilter] = useState<string>();
    const [adamsAccountsFilter, setAdamsAccountsFilter] = useState<string>();

    const [linkedAccounts, setLinkedAccounts] = useToggleState(true);
    const [linkedPlatforms, setLinkedPlatforms] = useToggleState(true);
    const [linkedSymbols, setLinkedSymbols] = useToggleState(true);
    const [linkedTraders, setLinkedTraders] = useToggleState(true);
    
    const [sizes, setSizes] = useState(showAdamHack ? [20, 20, 40, 20] : [20, 40, 40]);

    useEffect(() => {
        if (showAdamHack) {
            setSizes([20, 20, 40, 20]);
        } else {
            setSizes([20, 40, 40]);
        }
    }, [showAdamHack]);

    const keyPrefix = userToKeyPrefix.get(username.toLowerCase()) || prefix;
    const restrictedUser = userToKeyPrefix.has(username.toLowerCase());
    
    const [currentTab, setCurrentTab] = useState('accounts');

    useEffect(() => { if (!linkedPlatforms) setAccountsFilter(undefined); }, [linkedPlatforms]);
    useEffect(() => { if (!linkedAccounts) setPositionsFilter(undefined); }, [linkedAccounts]);
    useEffect(() => { if (!linkedSymbols) setPositionsFilter(undefined); }, [linkedSymbols]);
    useEffect(() => { if (!linkedTraders) setPositionsFilter(undefined); }, [linkedTraders]);

    const contextMenuItemsCreator = useCallback((table: string): ContextMenuItemsCreator => {
        return row => {
            const filters: string[] = [];
            switch (table) {
                case 'positions':
                case 'symbols':
                    filters.push(`(str_cmp(Symbol, '$${row.Symbol}') == 0)`);
                case 'accounts':
                    filters.push(`(str_cmp(Account, '$${row.Account}') == 0)`);
                case 'platforms':
                    filters.push(`(str_cmp(Platform, '$${row.Platform}') == 0)`);
            }
            const filterText = filters.join(' and ');
            return [
                {
                    name: 'Fills',
                    action: () => {
                        openPopout(
                            { sym: 'AllExecutions', ex: 'T' },
                            [],
                            undefined,
                            filterText,
                            'Fills'
                        );
                    }
                },
                {
                    name: 'Open Orders',
                    action: () => {
                        openPopout(
                            { sym: 'AllOrders', ex: 'T' },
                            [],
                            undefined,
                            `(${filterText}) and (RemainingShares > 0)`,
                            'Open Orders'
                        );
                    }
                }
            ];
        };
    }, []);

    function clearAccountsFilter() {
        setAccountsFilter(undefined);
    }
    function clearPositionsFilter() {
        setPositionsFilter(undefined);
    }

    const switchToTab = useCallback((_, tabName: 'accounts' | 'symbols' | 'traders') => {
        setCurrentTab(tabName);
    }, []);

    function platformsPreferences(field: FieldDescriptor): ColumnPreference {
        return {
            ...globalPreferences(field)!,
            flashOnChange: false
        };
    }
    function accountsPreferences(field: FieldDescriptor): ColumnPreference {
        let pref = globalPreferences(field);
        if (field.name.endsWith('PnL')) {
            pref = { name: field.name, ...pref, flashOnChange: false };
        }
        return pref;
    }
    function symbolsPreferences(field: FieldDescriptor): ColumnPreference {
        return globalPreferences(field);
    }
    function tradersPreferences(field: FieldDescriptor): ColumnPreference {
        return globalPreferences(field);
    }
    function positionsPreferences(field: FieldDescriptor): ColumnPreference {
        let pref = globalPreferences(field);
        if (field.name.endsWith('Volume')) {
            pref = { name: field.name, ...pref, flashOnChange: false };
        }
        return pref;
    }

    const save = useCallback(async () => {
        const key = prompt(
            'What URL key should be used?',
            match.params.key
        );
        if (!key) return;

        const mapState = (state: RiskTableSaveData): TableStateContract => (state && {
            sort: state.sortModel,
            filter: state.filter,
            columnOrder: state.columnOrder,
            columnVisibility: state.columnVisibility,
            columnWidths: state.columnWidths,
            firstRow: state.firstRow,
            pinnedColumns: state.pinnedColumns
        });

        const payload: NewRiskScreenContract = {
            key,
            shared: false,
            sizes,
            accountsState: accountsTableState && mapState(accountsTableState),
            tradersState: tradersTableState && mapState(tradersTableState),
            platformsState: platformsTableState && mapState(platformsTableState),
            positionsState: positionsTableState && mapState(positionsTableState),
            symbolsState: symbolsTableState && mapState(symbolsTableState),
        };
        await upsertRiskScreen(payload);

        logger.debug('saved state to', key, payload);

        if (key !== match.params.key) {
            history.push(`/risk/${key}`);
        }
    }, [accountsTableState, history, match.params.key, platformsTableState, positionsTableState, sizes, symbolsTableState, tradersTableState]);

    useEffect(() => {
        const keydown$ = fromEvent<KeyboardEvent>(window, 'keydown');
        const sub = keydown$.pipe(filter(x => x.ctrlKey && x.key === 's'), tap(x => x.preventDefault())).subscribe(() => save());
        return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [save]);

    useEffect(() => {
        const key = match.params.key;
        if (key) {
            (async () => {
                const payload = await getRiskScreenByKey(key);
                if (payload) {
                    const mapState = (state: TableStateContract | undefined): RiskTableSaveData | undefined => (state && {
                        filter: state.filter,
                        filterModel: undefined,
                        firstRow: undefined,
                        columnOrder: state.columnOrder,
                        columnVisibility: state.columnVisibility,
                        columnWidths: state.columnWidths,
                        pinnedColumns: state.pinnedColumns,
                        sortModel: state.sort
                    });

                    setAccountsTableState(mapState(payload.accountsState));
                    setSymbolsTableState(mapState(payload.symbolsState));
                    setTradersTableState(mapState(payload.tradersState));
                    setPositionsTableState(mapState(payload.positionsState));
                    setPlatformsTableState(mapState(payload.platformsState));
                    
                    setSizes(payload.sizes);

                    logger.debug('loaded state from', key, payload);
                }
            })();
        }
    }, [match.params.key]);

    const onPlatformsRowSelected = useCallback((rows: Dictionary[]) => {
        if (!linkedPlatforms) return;
        // console.trace('rows selected', rows);

        setAccountsFilter(rows.map(row => `(PositionKey like '%.*:${row.PositionKey}')`).join(' or ') || undefined);
    }, [linkedPlatforms]);

    const onAccountsRowSelected = useCallback((rows: Dictionary[]) => {
        if (!linkedAccounts) return;

        setPositionsFilter(rows
            .map(row => {
                const [account, platform] = row.PositionKey.split(':');
                return `(PositionKey like '%${account}-.*:${platform}')`;
            })
            .join(' or ') || undefined
        );
    }, [linkedAccounts]);

    const onSymbolsRowSelected = useCallback((rows: Dictionary[]) => {
        if (!linkedSymbols) return;

        setPositionsFilter(rows
            .map(row => `(str_cmp(Symbol, '$${row.Symbol}') == 0)`)
            .join(' or ') || undefined
        );
    }, [linkedSymbols]);

    const onTradersRowSelected = useCallback((rows: Dictionary[]) => {
        if (!linkedTraders) return;

        setPositionsFilter(rows
            .map(row => `(str_cmp(OwnerID, '$${row.TraderID}') == 0)`)
            .join(' or ') || undefined
        );
    }, [linkedTraders]);

    const accountsSymbolsNav = <Tabs value={currentTab} onChange={switchToTab}>
        <Tab value="accounts" label="Accounts"/>
        <Tab value="symbols" label="Symbols"/>
        <Tab value="traders" label="Traders"/>
    </Tabs>;

    const platformsKey: Key = useMemo(() => ({sym: `${keyPrefix}Platforms`, ex: 'T'}), [keyPrefix]);
    const accountsKey: Key = useMemo(() => ({sym: `${keyPrefix}Accounts`, ex: 'T'}), [keyPrefix]);
    const positionsKey: Key = useMemo(() => ({sym: `${keyPrefix}Positions`, ex: 'T'}), [keyPrefix]);

    const smallBecomesZero = (value: number) => value < 1e-6 && value > -1e-6 ? 0 : value;

    return (
        <div id="risk-page">
            <Split direction="vertical" gutterStyle={() => ({})} onDragEnd={setSizes} sizes={sizes}>
                {!restrictedUser && <div id="platforms">
                    <RiskTable
                        columnPreferences={[
                            { name: 'PositionKey', hidden: true, disabled: true },
                            'Platform',
                            'PnL',
                            'OpenPnL',
                            'ClosedPnL',
                            'MarketValue',
                            'Exposure',
                            'LongExposure',
                            'ShortExposure',
                            'SharesTraded',
                            'OpenOrders',
                            'PendingSharesBuy',
                            'PendingSharesSell',
                            'PendingNotionalBuy',
                            'PendingNotionalSell',
                        ]}
                        defaultColumnPreference={{hidden: true}}
                        columnPreference={platformsPreferences}
                        columnRankByIndex={true}
                        tableKey={platformsKey}
                        onRowSelected={onPlatformsRowSelected}
                        contextMenuItemsCreator={restrictedUser ? undefined : contextMenuItemsCreator('platforms')}
                        saveData={platformsTableState}
                        onSaveDataChanged={setPlatformsTableState}
                        linked={linkedPlatforms}
                        onLinkedChanged={setLinkedPlatforms}
                        onRebind={clearAccountsFilter}
                        title="Platforms"
                    />
                </div>}
                <div className="dark" id="accounts-symbols">
                    <div hidden={currentTab !== 'accounts'}>
                        <RiskTable
                            header={restrictedUser ? undefined : accountsSymbolsNav}
                            columnPreferences={[
                                { name: 'PositionKey', hidden: true, disabled: true },
                                { name: 'AccountPropertyKey', hidden: true },
                                { name: 'OwnerID', hidden: true },
                                'UpdateTime',
                                'Trader',
                                'TotalPnL',
                                'TotalavOpenPnL',
                                'TotalavClosedPnL',
                                'TotalMarketValue',
                                'TotalExposure',
                                'TotalLongExposure',
                                'TotalShortExposure',
                                'TotalSharesTraded',
                                'TotalOpenOrders',
                                'TotalPendSharesBuy',
                                'TotalPendSharesSell',
                                'PendingNotionalBuy',
                                'PendingNotionalSell',
                                'PendLongExposure',
                                'PendShortExposure'
                            ]}
                            defaultColumnPreference={{hidden: true}}
                            columnPreference={accountsPreferences}
                            columnRankByIndex={true}
                            tableKey={accountsKey}
                            onRowSelected={onAccountsRowSelected}
                            filter={accountsFilter}
                            saveData={accountsTableState}
                            onSaveDataChanged={setAccountsTableState}
                            contextMenuItemsCreator={restrictedUser ? undefined : contextMenuItemsCreator('accounts')}
                            linked={linkedAccounts}
                            onLinkedChanged={setLinkedAccounts}
                            onRebind={clearPositionsFilter}
                            hideTitle={!restrictedUser}
                            title={restrictedUser ? 'Traders' : 'Accounts'}
                        />
                    </div>
                    <div hidden={currentTab !== 'symbols'}>
                        <RiskTable
                            header={accountsSymbolsNav}
                            defaultColumnPreference={{hidden: true}}
                            columnPreferences={[
                                { name: 'PositionKey', hidden: true, disabled: true },
                                'UpdateTime',
                                'Symbol',
                                'LastPrice',
                                'TotalPnL',
                                'TotalOpenShares',
                                'TotalMarketValue',
                                'TotalExposure',
                                'TotalLongExposure',
                                'TotalShortExposure',
                                'TotalSharesTraded',
                                'TotalOpenOrders',
                                'TotalPendSharesBuy',
                                'TotalPendSharesSell',
                                'PendingNotionalBuy', // Not in table
                                'PendingNotionalSell' // Not in table
                            ]}
                            columnRankByIndex={true}
                            columnPreference={symbolsPreferences}
                            tableKey={{ sym: 'AllSymbols', ex: 'T' }}
                            onRowSelected={onSymbolsRowSelected}
                            filter={symbolsFilter}
                            saveData={symbolsTableState}
                            onSaveDataChanged={setSymbolsTableState}
                            linked={linkedSymbols}
                            onLinkedChanged={setLinkedSymbols}
                            contextMenuItemsCreator={restrictedUser ? undefined : contextMenuItemsCreator('symbols')}
                            onRebind={clearPositionsFilter}
                            hideTitle={true}
                            title="Symbols"
                        />
                    </div>
                    <div hidden={currentTab !== 'traders'}>
                        <RiskTable
                            header={accountsSymbolsNav}
                            defaultColumnPreference={{hidden: true}}
                            columnPreferences={[
                                'LastTime',
                                'Trader',
                                'TraderID',
                                'PnL',
                                'avOpenPnL',
                                'avClosedPnL',
                                'OpenShares', // Not in table
                                'MarketValue',
                                'Exposure',
                                'LongExposure', // Not in table unless 'TtlPendLongExposure'
                                'ShortExposure', // Not in table unless 'TtlPendShortExposure'
                                'SharesTraded',
                                'OpenOrders',
                                'PendSharesBuy',
                                'PendSharesSell',
                                'PendingNotionalBuy', // Not in table
                                'PendingNotionalSell' // Not in table
                            ]}
                            columnRankByIndex={true}
                            columnPreference={tradersPreferences}
                            tableKey={{ sym: 'AllTraders', ex: 'T' }}
                            onRowSelected={onTradersRowSelected}
                            filter={tradersFilter}
                            saveData={tradersTableState}
                            onSaveDataChanged={setTradersTableState}
                            linked={linkedTraders}
                            onLinkedChanged={setLinkedTraders}
                            contextMenuItemsCreator={restrictedUser ? undefined : contextMenuItemsCreator('traders')}
                            onRebind={clearPositionsFilter}
                            hideTitle={true}
                            title="Traders"
                        />
                    </div>
                </div>
                <div id="positions">
                    <RiskTable
                        columnRankByIndex={true}
                        defaultColumnPreference={{hidden: true}}
                        columnPreferences={[
                            { name: 'PositionPnLKey', hidden: true },
                            { name: 'PositionKey', hidden: true, disabled: true },
                            'Trader',
                            'Name',
                            'Symbol',
                            'OpenShares',
                            'RiskPnL',
                            'avOpenPnL',
                            'avClosedPnL',
                            'avAvgPx',
                            'RiskPrice',
                            'PendingSharesBuy',
                            'PendingSharesSell&SShort',
                            'MinSinceLast',
                            'SharesTraded',
                            'MarketValue',
                            'TransactTime',
                            'SODPosition',
                            'ChangeQty',
                            'Platform'
                        ]}
                        columnPreference={positionsPreferences}
                        contextMenuItemsCreator={restrictedUser ? undefined : contextMenuItemsCreator('positions')}
                        tableKey={positionsKey}
                        filter={positionsFilter}
                        saveData={positionsTableState}
                        onSaveDataChanged={setPositionsTableState}
                        title="Positions"
                    />
                </div>
                {showAdamHack && <div id="adam-hack">
                    <RiskTable
                        columnRankByIndex={true}
                        defaultColumnPreference={{hidden: true}}
                        columnPreferences={[
                            { name: 'PositionKey', hidden: true, disabled: true },
                            'UpdatedTime',
                            'Platform',
                            'Trader',
                            'account',
                            'PnL',
                            'AvOpenPnL',
                            'AvClosedPnL',
                            'Exposure',
                            'MarketValue',
                            'SharesTraded',
                            'AbsSlippageMarketValue',
                            'SlippageMarketValue',
                            'SlippagePnL',
                            {name: 'ratio', renderer: value => {
                                const res = isNumber(value) && !isNaN(value) ? numeral(value).format('0,0.00') : value;
                                return res;
                            }},
                            'BenchmarkAccount',
                            'BenchmarkName',
                            'BenchmarkPnL',
                            'BenchmarkMarketValue',
                            'BenchmarkSharesTraded',
                            'SlippageVolume',
                            '%SlippagePnL',
                            '%SlippageVolume',
                            '%SlippageMarketValue'
                        ]}
                        // columnPreference={this.accountsPreferences}
                        // contextMenuItemsCreator={this.contextMenuItemsCreator('accounts')}
                        tableKey={{ sym: 'AdamAccounts', ex: 'T' }}
                        filter={adamsAccountsFilter}
                        saveData={adamsAccountsTableState}
                        onSaveDataChanged={setAdamsAccountsTableState}
                        title="Joined Accounts"
                    />
                </div>}
            </Split>
        </div>
    );
};

const hackUsernames = ['adam', 'jchin', 'jeevaka', 'bytenik', 'david', 'dpfeff', 'vgoldf', 'alewen', 'jdassa', 'jfrase'];

export default connect(withRouter(RiskPage), (store: Store<AppState>) => {
    const props = store.watch(state => {
        const user: any = state.accessToken && jwt_decode(state.accessToken);
        let username: string | undefined = user && user.login.toLowerCase();
        const values = queryString.parse(window.location.search);
        if (values.masq) username = values.masq as string;

        return {
            showAdamHack: username ? hackUsernames.includes(username) : undefined,
            username
        };
    });
    return {props};
});
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import {WidgetContainer, WidgetComponent} from '../widget';
import { StrategyWidgetModel, GraphModel, GraphSettings, KnownGraphSettings, GraphType } from '../../model';
import {StrategySelector} from '../../../strategy/selector';
import { StrategyRecord, playStrategy } from '../../../services/strategies';
import { useModelCommitter } from '../../../hooks/useModelCommitter';

import Highcharts, { AxisTypeValue, Series, TooltipFormatterCallbackFunction } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {HighchartsReflow} from '../../../components/highcharts-reflow';
import { TextField, MenuItem, Select, Button } from '@material-ui/core';
import { map, flatMap, toArray, reduce, auditTime, tap, filter, throttleTime, catchError } from 'rxjs/operators';
import useLazyRef from '../../../hooks/useLazyRef';
import { TableClient, FieldDescriptor, SimpleTableClient, RowUpdate, CategoryForFieldType, FieldTypeCategory, SortModel } from '@thinkalpha/table-client';
import { Client } from '@thinkalpha/table-client/dist/client';
import {FieldSelector, MultipleFieldSelector} from '../../../components/field-selector/field-selector';

import {connect} from 'reactive-state/react';
import { Store } from 'reactive-state';
import {AppState} from '../../../state';
import {mapOnto} from '../../../util/mapOnto';
import { Subscription, Subject, combineLatest, empty } from 'rxjs';
import {combineLatestConflated} from '../../../operators/combineLatestConflated';

import { useMultipleSelectCallback } from '../../../hooks/useInputState';
import { useStateRef } from '../../../hooks/useStateRef';

import loglevel from 'loglevel';
import { ChartSettings } from './settings';
import { useToggleState } from '../../../hooks/useToggleState';
import _ from 'lodash';
const log = loglevel.getLogger('chart');

const defaultChartOptions: Partial<Highcharts.Options> = {
    credits: {enabled: false},
    title: {
        text: null as any
    },
    boost: {
        enabled: true,
        useGPUTranslations: true,
        usePreallocated: true
    },
    chart: {
        plotBackgroundColor: null as any,
        plotBorderWidth: null as any,
        plotShadow: false,
        zoomType: 'xy',
        pinchType: 'xy',
        styledMode: true
        // height: '100%',
        // type: 'pie',
        // width: null as any
    },
    xAxis: {
    },
    // tooltip: {
    //     pointFormat: '{series.name}: <strong>{point.percentage:.1f}%</strong>'
    // },
    plotOptions: {
        pie: {
            // allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                distance: -50,
                format: '<strong>{point.name}</strong>: {point.percentage:.1f} %',
                style: {
                    color: /*(Highcharts.theme && Highcharts.theme.contrastTextColor) || */'black'
                }
            }
        },
        scatter: {
            marker: {
                radius: 3
            }
        },
        line: {
            // dataLabels: {
            //     enabled: true
            // },
            // enableMouseTracking: true
        },
    },
};

function strategyRecordToId(sr: StrategyRecord | string | undefined) {
    switch (typeof sr) {
        case 'undefined':
            return undefined;
        case 'string':
            return sr;
        case 'object':
            return sr.id;
    }
}

const ChartWidget: WidgetComponent<{client: Client}, GraphModel> = ({model, client, onChange, ...wp}) => {
    const [fieldsRef, setFields] = useStateRef<FieldDescriptor[]>();

    const chartRef = useRef<HighchartsReact>(null);
    
    const [settingsOpen,, showSettings, hideSettings] = useToggleState(false);
    const [settingsRef, setSettings] = useStateRef<KnownGraphSettings>(model?.settings);
    const [sortRef, setSort] = useStateRef<SortModel>(model?.sort);
    const [strategyRecord, setStrategyRecord] = useState<StrategyRecord | string | undefined>(model?.strategyId);

    useModelCommitter(
        model,
        onChange,
        {settings: settingsRef.current, sort: sortRef.current},
        ({settings, sort}) => {
            if (!settings) return false;
            if (!sort) return false;
            if (!strategyRecord) return false;

            const model: GraphModel = {
                settings,
                sort,
                strategyId: strategyRecordToId(strategyRecord)!
            };
            return model;
        },
        model => {
            if (!model) {
                // setSettings(undefined)
            } else {
                setStrategyRecord(model.strategyId);
                setSettings(model.settings);
                setSort(model.sort);
            }
        }
    );

    /// materialize dimensions
    useEffect(() => {
        const chart = chartRef.current;
        for (const series of chart?.chart.series ?? []) {
            series.remove();
        }

        const settings = settingsRef.current;
        if (!settings) return;
        
        switch (settings.graphType) {
            case GraphType.line:
            case GraphType.scatter:
                seriesDimensionsRef.current = settings.yDimensions.map(ydim => [settings.xDimension, ydim]);
                break;
            case GraphType.pie:
                seriesDimensionsRef.current = [[
                    settings.categoryDimension,
                    settings.magnitudeDimension
                ]];
                break;
            case GraphType.bar:
                seriesDimensionsRef.current = [[
                    settings.categoryDimension,
                    settings.magnitudeDimension,
                    ...settings.widthDimension ? [settings.widthDimension] : []
                ]];
                break;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsRef.current]);
    
    const seriesDimensionsRef = useRef<string[][]>();

    const tcRef = useLazyRef(() => new SimpleTableClient(client));
    const refreshPulse$Ref = useLazyRef(() => new Subject<void>());

    const symbolsRef = useRef<string[]>([]);
    const rowCountRef = useRef<number>();
    
    useEffect(() => {
        const tc = tcRef.current;
        tc.bounds = {firstRow: 0, windowSize: 0};

        const subs = new Subscription();
        subs.add(tc.descriptor$.subscribe(fields => {
            setFields(fields);
        }));
        const buffer = [];
        subs.add(tc.rowCount$.subscribe(rc => rowCountRef.current = rc));
        subs.add(combineLatestConflated([tc.updates$, refreshPulse$Ref.current]).pipe(
            map(([upds]) => upds),
            filter(x => !!x),
            throttleTime(5000),
            tap(upds => {
                let updi = 0;
                const seriesDimensions = seriesDimensionsRef.current;
                if (!seriesDimensions) return;
                const chart = chartRef.current!.chart;

                upds.subscribe(upd => {
                    symbolsRef.current[updi] = upd['Symbol'];

                    const chartType = settingsToHighchartsType(settingsRef.current!);
                    while (chart.series.length && chart.series[0].type !== chartType) {
                        chart.series[0].remove();
                    }
                    for (let seriesi = 0; seriesi < seriesDimensions.length; seriesi++) {
                        const series = chart.series[seriesi];
                        const dimensions = seriesDimensions[seriesi];

                        if (!series) {
                            const initial = [dimensions.map(x => upd[x])];
                            chart.addSeries({
                                type: chartType,
                                data: initial as any,
                                boostThreshold: 250,
                                name: dimensions.join(' vs ')
                            }, false);
                        } else if (updi >= series.data.length) {
                            const newVals = dimensions.map(x => upd[x]); // conflation does not work here
                            series.addPoint(newVals, false, false);
                        } else {
                            const newVals = mapOnto(dimensions, buffer, x => upd[x]); // conflation here seems to be okay
                            series.data[updi].update(newVals, false);
                        }
                    }
                    updi++;
                });

                // console.log('series 0', chart.series[0].data);
                chart.redraw(false);
            }),
            catchError(err => {
                log.error('Got error from chart pipeline', err);
                return empty();
            })
        ).subscribe());

        return () => {
            tc.dispose();
            subs.unsubscribe();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const tc = tcRef.current;
        tc.sort = sortRef.current ?? {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortRef.current]);

    // automatically update the tc bounds
    useEffect(() => {
        const tc = tcRef.current;
        tc.baseKey = undefined;
        // tc.bounds = {firstRow: 0, windowSize: 0};
        setFields(undefined);

        // clear chart
        const chart = chartRef.current!.chart;
        for (const series of chart.series) {
            series.remove(false);
        }
        chart.redraw(false);

        if (!strategyRecord) return;
        
        const sub = playStrategy(strategyRecord).subscribe(strat => {
            if (!strat) {
                log.warn('Failed to play strategy:', strategyRecord);
            } else {
                tc.baseKey = strat.tableName ? {sym: strat.tableName, ex: 'T'} : undefined;
            }
        });
        return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [strategyRecord]);

    useEffect(() => {
        const tc = tcRef.current;
        const settings = settingsRef.current;

        if (!settings) {
            tc.bounds = {firstRow: 0, windowSize: 0};
        } else {
            tc.bounds = {firstRow: 0, windowSize: 10000};
            refreshPulse$Ref.current.next();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsRef.current]);

    const xaxisType = useMemo(() => {
        const settings = settingsRef.current;
        switch (settings?.graphType) {
            case GraphType.bar:
                return 'category';
            case GraphType.line: {
                const field = settings.xDimension;
                const def = fieldsRef.current?.find(f => f.name === field);
                const cat = CategoryForFieldType.get(def?.type!);
                return categoryToAxisType(cat);
            }
            default:
                return 'category';
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsRef.current, fieldsRef.current]);

    const options: Highcharts.Options = {
        ...defaultChartOptions,
        xAxis: {
            ...defaultChartOptions.xAxis,
            type: xaxisType,
            events: {
                afterSetExtremes: evt => {

                }
            }
        },
        yAxis: {
            ...defaultChartOptions.yAxis,
            events: {
                afterSetExtremes: evt => {

                }
            }
        },
        tooltip: {
            formatter: useCallback<TooltipFormatterCallbackFunction>(function tooltip() {
                // this.point.category
                let index = this.series.data.indexOf(this.point);
                if (index === -1) {
                    // this is a hack for boost mode
                    const xdata: number[] = (this.series as any).xData ?? [];
                    const ydata: number[] = (this.series as any).yData ?? [];
                    index = _(xdata)
                        .map((x, i) => x === this.point.x ? i : undefined)
                        .filter((x): x is number => x !== undefined)
                        .find(i => ydata[i] === this.point.y) ?? -1;
                }
                return symbolsRef.current[index];
            }, [])
        },
        chart: {
            ...defaultChartOptions.chart,
            events: {
                
            }
        }
    };

    const onSettingsSaved = useCallback((settings, sort) => {
        setSettings(settings);
        setSort(sort);
        hideSettings();
    }, [hideSettings, setSettings, setSort]);

    const header = <>
        <StrategySelector model={strategyRecord} onChange={setStrategyRecord} />
        {strategyRecord && <Button onClick={showSettings}>Open settings</Button>}
    </>;

    return <WidgetContainer {...wp} name="Chart" header={header}>
        <HighchartsReflow ref={chartRef} options={options} />
        {settingsOpen && <ChartSettings sort={sortRef.current || {}} settings={settingsRef.current!} fields={fieldsRef.current ?? []} open={settingsOpen} onSave={onSettingsSaved} onClose={hideSettings}/>}
    </WidgetContainer>;
};

const connected = connect(ChartWidget, (store: Store<AppState>) => {
    const props = store.watch(state => {
        return {
            client: state.client,
        };
    });
    return {props};
});

export {connected as ChartWidget};

function categoryToAxisType(type: FieldTypeCategory | undefined): AxisTypeValue {
    switch (type) {
        case FieldTypeCategory.Double:
            return 'linear';
        case FieldTypeCategory.Timestamp:
            return 'datetime';
        default:
            return 'category';
    }
}

function settingsToHighchartsType(settings: KnownGraphSettings): 'line' | 'scatter' | 'bar' | 'variwide' | 'pie' {
    switch (settings.graphType) {
        case GraphType.scatter:
        case GraphType.line:
        case GraphType.pie:
            return settings.graphType;
        case GraphType.bar:
            if (settings.widthDimension) {
                return 'variwide';
            } else {
                return 'bar';
            }
        default:
            throw new Error(`Graph type not implemented: ${(settings as any).graphType}`);
    }
}
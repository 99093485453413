import { SlidingWindow, PeriodType, BarLength, Timeframe, RelativePointInTime, AbsolutePointInTime, PointInTime, CountPointInTime } from './model';
import moment, { isMoment, MomentSetObject } from 'moment';
import _ from 'lodash';

import { isRelativePointInTime } from './timeframe';

export const barsAvailable: readonly BarLength[] = [
    {value: 1, period: PeriodType.second},
    {value: 5, period: PeriodType.second},
    {value: 10, period: PeriodType.second},
    {value: 30, period: PeriodType.second},
    {value: 1, period: PeriodType.minute},
    {value: 5, period: PeriodType.minute},
    {value: 10, period: PeriodType.minute},
    {value: 30, period: PeriodType.minute},
    {value: 1, period: PeriodType.hour},
    {value: 1, period: PeriodType.day}
];
Object.seal(barsAvailable);

export function barToString(bar: BarLength): string {
    return `_${bar.value}${bar.period}`;
}

const barParseRegex = /[_.]?([0-9]+)([a-zA-Z]+)/;
export function barParse(barStr: string): BarLength {
    const matches = barStr.match(barParseRegex);
    if (!matches || matches.length !== 3) throw new Error(`Invalid bar length: ${barStr}`);
    return {value: +matches[1], period: matches[2] as PeriodType};
}

export const PeriodInApproxSeconds: ReadonlyMap<PeriodType, number> = new Map<PeriodType, number>([
    [PeriodType.second, 1],
    [PeriodType.minute, 60],
    [PeriodType.hour, 60 * 60],
    [PeriodType.day, 60 * 60 * 24],
    [PeriodType.week, 60 * 60 * 24 * 7],
    // warning, these next few are not quite right but are the best we can do
    [PeriodType.month, 60 * 60 * 24 * 30],
    [PeriodType.quarter, Math.round(60 * 60 * 24 * 365 / 4)],
    [PeriodType.year, 60 * 60 * 24 * 365]
]);
Object.seal(PeriodInApproxSeconds);

export function convertRelativeToCurrentApproxAbsolute(point: RelativePointInTime): AbsolutePointInTime {
    if (point === null) return moment();
    
    const inSecs = point.periods.reduce((acc, x) => acc + x.value * PeriodInApproxSeconds.get(x.period)!, 0);
    const anchor: MomentSetObject | undefined = point.anchor && _.pickBy(point.anchor.toObject(), x => x && !isNaN(x));
    return moment().subtract(inSecs, 'seconds').set(anchor!);
}

export function barsBetween(timeframe: Timeframe) {
    if (timeframe.end === undefined) throw new Error('Only ranges are supported.');
    
    const barLength = timeframe.barLength;
    if (!barLength) throw new Error('Timeframe must have bar length to calculate bars between.');

    let start = timeframe.start;
    let end = timeframe.end;

    if (typeof start === 'number' || typeof end === 'number') {
        start = start || 0;
        end = end || 0;
        
        if (typeof start === 'number' && typeof end === 'number') {
            return start - end;
        } else {
            throw new Error('Cannot supply bar count for only one side.');
        }
    }

    if (!end) {
        end = moment();
        if (!isRelativePointInTime(start)) { // for static points in time with no end, we need to baseline to end of day
            if (end.isoWeekday() >= 6) {
                end.set({'isoWeekday': 1, 'h': 16, 'm': 30, 's': 0, 'ms': 0}); // "EOD" on Monday
            } else if (end.hour() > 16 || (end.hour() === 16 && end.minute() >= 30)) {
                end.set({'h': 16, 'm': 30, 's': 0, 'ms': 0}); // "EOD"

                if (end.isoWeekday() === 5) { // after hours on Friday
                    end.isoWeekday(1); // so go to Monday
                } else {
                    end.isoWeekday(end.isoWeekday() + 1); // move forward a day
                }
            } else {
                end.set({'h': 16, 'm': 30, 's': 0, 'ms': 0}); // "EOD"
            }
        }
    }

    if (isRelativePointInTime(start)) start = convertRelativeToCurrentApproxAbsolute(start);
    if (isRelativePointInTime(end)) end = convertRelativeToCurrentApproxAbsolute(end);

    const secondsBtwn = end.diff(start, 's');
    const barLengthInSecs = barLength.value * PeriodInApproxSeconds.get(barLength.period)!;

    return secondsBtwn / barLengthInSecs;
}
import {ColumnPreference} from '../components/table-view/model';

export const columnPreferences: readonly ColumnPreference[] = [
    {name: 'Symbol', rank: 0, pinned: 'left' }, 
    {name: 'Last_Price', rank: 1 }, 
    {name: 'Change', rank: 2 }, 
    {name: '%Chg', rank: 3 }, 
    {name: 'Volume', rank: 4 }, 
    {name: 'Open', rank: 5 }, 
    {name: '%Chg_open', rank: 6 }, 
    {name: 'Close', rank: 7 }, 
    {name: 'Close_adj', rank: 8 }, 
    {name: 'Last_Size', rank: 9 }, 
    {name: 'Last_Time', rank: 10 }, 
    {name: 'Bid_Size', rank: 11 }, 
    {name: 'Bid_Price', rank: 12 }, 
    {name: 'Ask_Price', rank: 13 }, 
    {name: 'Ask_Size', rank: 14 }, 
    {name: 'Spread', rank: 15 }, 
    {name: 'MidPrice', rank: 16 }, 
    {name: 'Open_1D', rank: 17 }, 
    {name: 'High_1D', rank: 18 }, 
    {name: 'Low_1D', rank: 19 }, 
    {name: 'Low_52wk', rank: 20 }, 
    {name: 'High_52wk', rank: 21 }, 
    {name: 'ADV_20D', rank: 22 }, 
    {name: 'Dividend_Yield', rank: 23 }, 
    {name: 'MarketCap', rank: 24 }, 
    {name: 'PE_Ratio', rank: 25 }, 
    {name: 'EMA_1D', rank: 26 }, 
    {name: 'EMA_1W', rank: 27 }, 
    {name: 'EMA_10D', rank: 28 }, 
    {name: 'EMAV_1D', rank: 29 }, 
    {name: 'EMAV_10D', rank: 30 }, 
    {name: 'MACD_X1', rank: 31 }, 
    {name: 'SMA_5D', rank: 32 }, 
    {name: 'SMA_10D', rank: 33 }, 
    {name: 'SMA_20D', rank: 34 }, 
    {name: 'SMA_200D', rank: 35 },
    {name: 'VWAP_1D', rank: 36 },
];
for (const pref of columnPreferences) Object.seal(pref);
Object.seal(columnPreferences);

export default columnPreferences;
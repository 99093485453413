import _ from 'lodash';

import loglevel from 'loglevel';
const log = loglevel.getLogger('parser');

import { FieldDescriptor, FieldTypeCategory, TypesInTypeCategory, FieldTypeCategoryNames } from '@thinkalpha/table-client';
import wu from 'wu';
import { functionDefs } from './functions';
import lexer from './lexer';
import { CompletionOption, CompletionType, Section, Token, TokenType, Field } from './model';
import {allBinaryOperators, FilterSymbol, LogicalOperator, MathOperator, NumericComparisonOperator, StringComparisonOperator} from './symbols';
import {ParserResult as AstParserResult} from './parser.ast';

import { overlappingRange } from '../../util/overlappingRange';

import newParser from './parser.ast';
import {flattenAst} from '../../components/filter-editor/ast';
import {AstNode, AstNodeType, KnownAstNode, BinaryOperationNode} from './ast';

export function annotateTokens(tokens: readonly Token[], parserResult: AstParserResult, dataTypeRequired?: FieldTypeCategory): Section[] {
    const flat = flattenAst(parserResult.root);

    const errors = [..._.flatMap(flat, x => x.errors.map(err => ({...err, range: err.range || x.range}))), ...parserResult.errors];
    if (dataTypeRequired) {
        if (!parserResult.root || parserResult.root.dataType !== dataTypeRequired) {
            errors.push({
                error: `Expected type ${FieldTypeCategoryNames.get(dataTypeRequired)}` + (parserResult.root && parserResult.root.dataType ? `, but instead found ${FieldTypeCategoryNames.get(parserResult.root.dataType)}` : ''),
                range: {start: 0, end: parserResult.text.length}
            });
        }
    }
    const tokenNodes = tokens.map(tk => _(flat)
        .filter(node => overlappingRange(node.range, tk.range))
        .minBy(node => node.range.end - node.range.start)!);
    const type = parserResult.root && parserResult.root.dataType;
    const annotated: Section[] = [
        ...tokens.map((tk, i): Section => ({
            text: tk.token,
            source: tk,
            errors: [],
            node: tokenNodes[i],
            range: {start: tk.range.start, end: tk.range.end}
        })),
    ];

    // reduce errors
    const shrunkErrors = errors.filter(err => {
        const otherErrors = _(errors).filter(x => x !== err);
        const thatOverlap = otherErrors.filter(x => overlappingRange(err.range!, x.range));
        const withRangeLengthsOf = thatOverlap.map(x => x.range!.end - x.range!.start + 1).value();

        const myLen = err.range.end - err.range.start + 1;

        return withRangeLengthsOf.every(x => x >= myLen);
    });

    for (const annotation of annotated) {
        const overlappingErrors = shrunkErrors.filter(x => overlappingRange(annotation.range, x.range));
        annotation.errors = overlappingErrors.map(x => x.error);
    }

    return annotated;
}

export interface ParserError {
    source: Token;
    error: string;
}


export default annotateTokens;
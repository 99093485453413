import React, { useEffect, useState } from 'react';

import { Step, StepContent, StepLabel, Typography } from '@material-ui/core';
import { StepRecord } from '../../demo/wizard/wizard';
import { useInputState } from '../../hooks/useInputState';
import { Universe } from '../model';
import {PremadeUniverseSelector} from '../premade-universe-selector';
import {UniverseCreator} from '../universe-creator';
import {SpecialResult, UniverseSelector} from '../universe-selector';

import { WIZARD_CREATE_UNIVERSE } from '../../text/wizard-steps-text';

export function useUniverseSteps(universe: Universe | undefined, onChange: (universe?: Universe) => void): StepRecord[] {
    const [universeSelection, setUniverseSelection] = useState<Universe | SpecialResult | undefined>(undefined);

    useEffect(() => {
        if (universeSelection !== SpecialResult.create) {
            onChange(universeSelection);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [universeSelection]);

    const unfiltered: (StepRecord | false)[] = [
        {
            title: 'Select Universe',
            valid: true,
            content: <>
                <UniverseSelector universe={universeSelection} onChange={setUniverseSelection} />
            </>
        }, universeSelection === 'create' && {
            title: 'Create Universe',
            valid: universe !== undefined,
            content: <>
                <Typography>{WIZARD_CREATE_UNIVERSE}</Typography>
                <UniverseCreator model={universe} onChange={onChange} />
            </>
        }
        // , universeSelection === 'premade' && {
        //     title: 'Choose a Premade Universe List',
        //     valid: true,
        //     content: <>
        //         <Typography>Bacon ipsum dolor amet short ribs brisket venison rump drumstick pig sausage prosciutto chicken spare ribs salami picanha doner. Kevin capicola sausage, buffalo bresaola venison turkey shoulder picanha ham pork tri-tip meatball meatloaf ribeye. Doner spare ribs andouille bacon sausage. Ground round jerky brisket pastrami shank.</Typography>
        //         <PremadeUniverseSelector value={universe} onChange={onChange} />
        //     </>
        // }
    ];
    
    return unfiltered.filter<StepRecord>((x): x is StepRecord => !!x);
}
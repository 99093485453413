import {Field, HistoricType} from '../components/filter-editor/model';
import { FieldTypeCategory, CategoryForFieldType, RawClient, TableClient } from '@thinkalpha/table-client';
import { strategyDescriptors } from '../strategy/creators/if-then/descriptors';

import store from '../state';
import { switchMap, filter, map, scan, tap, publishBehavior, publishReplay, refCount } from 'rxjs/operators';
import { descriptorToField } from '../components/data-builder/data-builder';

const historicStrategyFields: readonly Field[] = [
    // { name: 'RecordStatus', sourceTable: 'LevelOneSnapshot', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'MsgSequence', sourceTable: 'LevelOneSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'Time', sourceTable: 'LevelOneSnapshot', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    // { name: 'BidMarket', sourceTable: 'LevelOneSnapshot', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'BidTime', sourceTable: 'LevelOneSnapshot', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    // { name: 'BidPrice', sourceTable: 'LevelOneSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'BidVolume', sourceTable: 'LevelOneSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'AskMarket', sourceTable: 'LevelOneSnapshot', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'AskTime', sourceTable: 'LevelOneSnapshot', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    // { name: 'AskPrice', sourceTable: 'LevelOneSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'AskVolume', sourceTable: 'LevelOneSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'ExegyRefreshQuals', sourceTable: 'LevelOneSnapshot', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },

    // { name: 'RecordStatus', sourceTable: 'LevelOneUpdate', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'MsgSequence', sourceTable: 'LevelOneUpdate', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'Time', sourceTable: 'LevelOneUpdate', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    { name: 'BidMarket', sourceTable: 'LevelOneUpdate', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'BidTime', sourceTable: 'LevelOneUpdate', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    { name: 'BidPrice', sourceTable: 'LevelOneUpdate', type: FieldTypeCategory.Double, historic: HistoricType.history },
    { name: 'BidVolume', sourceTable: 'LevelOneUpdate', type: FieldTypeCategory.Double, historic: HistoricType.history },
    { name: 'AskMarket', sourceTable: 'LevelOneUpdate', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'AskTime', sourceTable: 'LevelOneUpdate', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    { name: 'AskPrice', sourceTable: 'LevelOneUpdate', type: FieldTypeCategory.Double, historic: HistoricType.history },
    { name: 'AskVolume', sourceTable: 'LevelOneUpdate', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'ExegyRefreshQuals', sourceTable: 'LevelOneUpdate', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    
    // { name: 'RecordStatus', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'MsgSequence', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'Time', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    // { name: 'LastTime', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    // { name: 'LastPrice', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'LastVolume', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'LastMarket', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'InstrumentStatus', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'InstrumentStatusTime', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    // { name: 'PrevClose', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'OpenPrice', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'OpenTime', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    // { name: 'HighPrice', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'HighTime', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    // { name: 'LowPrice', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'LowTime', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    // { name: 'ClosePrice', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'CloseTime', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    // { name: 'TotalVolume', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'VWAPVolume', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'TradedValue', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'TradeSource', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'TickFlags', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'ExegyInstrumentStatus', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'ExegyMarketStatus', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'ExegyRefreshQuals', sourceTable: 'TradeSnapshot', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },

    // { name: 'RecordStatus', sourceTable: 'TradeCorrection', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'MsgSequence', sourceTable: 'TradeCorrection', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'Time', sourceTable: 'TradeCorrection', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    // { name: 'InstrumentStatus', sourceTable: 'TradeCorrection', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'OpenPrice', sourceTable: 'TradeCorrection', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'OpenTime', sourceTable: 'TradeCorrection', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    // { name: 'HighPrice', sourceTable: 'TradeCorrection', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'HighTime', sourceTable: 'TradeCorrection', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    // { name: 'LowPrice', sourceTable: 'TradeCorrection', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'LowTime', sourceTable: 'TradeCorrection', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    // { name: 'ClosePrice', sourceTable: 'TradeCorrection', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'CloseTime', sourceTable: 'TradeCorrection', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    // { name: 'TotalVolume', sourceTable: 'TradeCorrection', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'VWAPVolume', sourceTable: 'TradeCorrection', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'TradedValue', sourceTable: 'TradeCorrection', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'TradeSource', sourceTable: 'TradeCorrection', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'TickFlags', sourceTable: 'TradeCorrection', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'ExegyInstrumentStatus', sourceTable: 'TradeCorrection', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'ExegyMarketStatus', sourceTable: 'TradeCorrection', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'ExegyRefreshQuals', sourceTable: 'TradeCorrection', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },

    // { name: 'RecordStatus', sourceTable: 'TradeUpdate', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'MsgSequence', sourceTable: 'TradeUpdate', type: FieldTypeCategory.Double, historic: HistoricType.history },
    { name: 'Time', sourceTable: 'TradeUpdate', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    { name: 'TradePrice', sourceTable: 'TradeUpdate', type: FieldTypeCategory.Double, historic: HistoricType.history },
    { name: 'TradeVolume', sourceTable: 'TradeUpdate', type: FieldTypeCategory.Double, historic: HistoricType.history },
    { name: 'TradeMarket', sourceTable: 'TradeUpdate', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'PriceInd', sourceTable: 'TradeUpdate', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'InstrumentStatus', sourceTable: 'TradeUpdate', type: FieldTypeCategory.String, historic: HistoricType.history },
    { name: 'TotalVolume', sourceTable: 'TradeUpdate', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'TradeSource', sourceTable: 'TradeUpdate', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'TickFlags', sourceTable: 'TradeUpdate', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'ExegyRefreshQuals', sourceTable: 'TradeUpdate', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },

    // { name: 'RecordStatus', sourceTable: 'TradeStatusUpdate', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'MsgSequence', sourceTable: 'TradeStatusUpdate', type: FieldTypeCategory.Double, historic: HistoricType.history },
    // { name: 'Time', sourceTable: 'TradeStatusUpdate', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },
    // { name: 'InstrumentStatus', sourceTable: 'TradeStatusUpdate', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'TradeSource', sourceTable: 'TradeStatusUpdate', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'TickFlags', sourceTable: 'TradeStatusUpdate', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'ExegyInstrumentStatus', sourceTable: 'TradeStatusUpdate', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'ExegyMarketStatus', sourceTable: 'TradeStatusUpdate', type: FieldTypeCategory.String, historic: HistoricType.history },
    // { name: 'ExegyRefreshQuals', sourceTable: 'TradeStatusUpdate', type: FieldTypeCategory.Timestamp, historic: HistoricType.history },

    // { name: 'RecordStatus', sourceTable: 'BarRecord', type: FieldTypeCategory.String, historic: HistoricType.bars },
    // { name: 'Time', sourceTable: 'BarRecord', type: FieldTypeCategory.Timestamp, historic: HistoricType.bars },
    { name: 'BarRecordOpen', sourceTable: 'BarRecord', type: FieldTypeCategory.Double, historic: HistoricType.bars },
    { name: 'BarRecordHigh', sourceTable: 'BarRecord', type: FieldTypeCategory.Double, historic: HistoricType.bars },
    { name: 'BarRecordLow', sourceTable: 'BarRecord', type: FieldTypeCategory.Double, historic: HistoricType.bars },
    { name: 'BarRecordClose', sourceTable: 'BarRecord', type: FieldTypeCategory.Double, historic: HistoricType.bars },
    { name: 'BarRecordVolume', sourceTable: 'BarRecord', type: FieldTypeCategory.Double, historic: HistoricType.bars },
];
Object.seal(historicStrategyFields);

// *** this is a cheap hack to avoid hard coded fields. the dictionary should be used to avoid this once it is ready ***
let strategyFields: readonly Field[] = [];
let allStrategyFields: readonly Field[] = [];
{
    const tc$ = store.watch(x => x.client)
        .pipe(
            filter((x): x is RawClient => !!x),
            map(x => new TableClient(x, {sym: 'allUniverseIfThenBase', ex: 'T'})),
            scan((oldTc, newTc) => {
                if (oldTc) oldTc.dispose();
                return newTc;
            }),
            publishReplay(1),
            refCount()
        );

    tc$.pipe(
        switchMap(x => x.descriptor$),
        // tap(x => console.log('got descriptors', x)),
        map(x => x.map(descriptorToField))
    ).subscribe(x => {
        strategyFields = x;
        Object.seal(strategyFields);
        allStrategyFields = [...historicStrategyFields, ...x];
        Object.seal(allStrategyFields);
    });

    tc$.subscribe(x => x.bounds = {firstRow: 0, windowSize: 0});
}
// *** end cheap hack ***

export {strategyFields, allStrategyFields, historicStrategyFields};
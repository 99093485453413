export enum FilterSymbol {
    '==' = '==',
    '(' = '(',
    ')' = ')',
    'like' = 'like',
    '<' = '<',
    '>' = '>',
    '<=' = '<=',
    '!=' = '!=',
    '>=' = '>=',
    '+' = '+',
    '-' = '-',
    ',' = ',',
    'or' = 'or',
    '*' = '*',
    '/' = '/',
    '%' = '%',
    'and' = 'and',
}

export enum MathOperator {
    '+' = '+',
    '-' = '-',
    '*' = '*',
    '/' = '/',
    '%' = '%'
}

export enum LogicalOperator {
    'or' = 'or',
    'and' = 'and'
}

export enum NumericComparisonOperator {
    '==' = '==',
    '!=' = '!=',
    '<' = '<',
    '<=' = '<=',
    '>' = '>',
    '>=' = '>='
}

export enum GroupingSymbol {
    '(' = '(',
    ')' = ')'
}

export enum StringComparisonOperator {
    'like' = 'like',
    '==' = '==',
    '!=' = '!=',
    '<' = '<',
    '<=' = '<=',
    '>' = '>',
    '>=' = '>='
}

export type BinaryOperator = (MathOperator | NumericComparisonOperator | StringComparisonOperator | LogicalOperator);

const symbolsInitializer = Object.keys(FilterSymbol).map<[string, FilterSymbol]>(x => [x, FilterSymbol[x]]);
export const Symbols = new Map<string, FilterSymbol>(symbolsInitializer);

export const allBinaryOperators: readonly string[] = [
    ...Object.keys(StringComparisonOperator),
    ...Object.keys(NumericComparisonOperator),
    ...Object.keys(LogicalOperator),
    ...Object.keys(MathOperator)
];
Object.seal(allBinaryOperators);

export const binaryOperatorPrecedences: readonly (readonly BinaryOperator[])[] = [
    [MathOperator['*'], MathOperator['/'], MathOperator['%']],
    [MathOperator['+'], MathOperator['-']],
    [NumericComparisonOperator['<'], NumericComparisonOperator['<='], NumericComparisonOperator['>'], NumericComparisonOperator['>='], NumericComparisonOperator['=='], NumericComparisonOperator['!='], StringComparisonOperator.like],
    [LogicalOperator.and, LogicalOperator.or]
];
for (const x of binaryOperatorPrecedences) Object.seal(x);
Object.seal(binaryOperatorPrecedences);

export enum UnaryOperator {
    '-' = '-',
    '+' = '+'
}

export const allUnaryOperators: readonly string[] = [
    ...Object.keys(UnaryOperator)
];
Object.seal(allUnaryOperators);
import _ from 'lodash';

import loglevel from 'loglevel';
const log = loglevel.getLogger('parser');
// log.setDefaultLevel(loglevel.levels.DEBUG);
log.setDefaultLevel(loglevel.levels.WARN);

const consoleWrapper = {groupCollapsed: (...args) => {}, groupEnd: (...args) => {}, group: (...args) => {}};
// eslint-disable-next-line no-console
// const consoleWrapper = {groupCollapsed: console.groupCollapsed, groupEnd: console.groupEnd, group: console.group};

import { FieldDescriptor, FieldTypeCategory, TypesInTypeCategory, FieldTypeCategoryNames, CategoryForFieldType } from '@thinkalpha/table-client';
import { functionDefs } from './functions';
import lexer from './lexer';
import { CompletionOption, CompletionType, Range, Section, Token, TokenType, Field } from './model';
import {allBinaryOperators, FilterSymbol, LogicalOperator, MathOperator, NumericComparisonOperator, StringComparisonOperator, allUnaryOperators, binaryOperatorPrecedences, BinaryOperator} from './symbols';
import * as Ast from './ast';
import { RangeType, RangeInfo, childrenOfNode, flattenAst } from './ast';

export interface ParserResult {
    text: string;
    root: Ast.AstNode | undefined;
    valid: boolean;
    errors: Required<Ast.ParserError>[];
}

type ParserTestResult<T extends Ast.AstNode = Ast.AstNode> = T | undefined;

type Test<T extends Ast.AstNode> = () => ParserTestResult<T>;

function parser(tokens: Token[], fields: readonly Field[]): ParserResult {
    if (!tokens) throw new TypeError('expected tokens argument');
    const lastIdx = tokens.length - 1;

    const text = tokens.map(x => x.token).join('');
    if (!text) {
        return {
            root: undefined,
            errors: [],
            valid: false,
            text
        };
    }

    consoleWrapper.groupCollapsed(`%cRunning parser on ${tokens.map(x => x.token).join('')}`, 'font-size: 2em');
    log.debug('fields', fields);
    log.debug('tokens', tokens);

    // const tokenStarts = tokens.reduce((acc, x, i) => {
    //     if (i === 0) {
    //         return [x.s]
    //     }
    //     acc[i] = acc[i-1]
    // }, []);

    let idx = 0;

    // const completionOptions: CompletionOption[] = [];
    const skippedTests: Test<Ast.AstNode>[] = [];
    function peek(offset = 0) {
        return tokens[idx + offset];
    }
    
    let lastRealToken: Token | undefined = undefined;
    let skippedWhitespace: Token[] = [];
    let lastSkippedWhitespace: Token | undefined;
    let lastTokenRangeWithWhitespace: Range;
    function consume() {
        let token: Token | undefined;
        skippedWhitespace = [];
        lastSkippedWhitespace = undefined;
        do {
            token = tokens[idx++];
            if (token && token.type === TokenType.Whitespace) {
                skippedWhitespace.push(token);
                lastSkippedWhitespace = token;
            }

            log.debug('consuming token', token, 'at index', idx);
        } while (token && token.type === TokenType.Whitespace); // skip whitespace tokens

        if (token) lastRealToken = token;

        if (skippedWhitespace[0] || token) {
            lastTokenRangeWithWhitespace = {
                start: skippedWhitespace[0] ? skippedWhitespace[0].range.start : token.range.start,
                end: token ? token.range.end : skippedWhitespace[skippedWhitespace.length - 1].range.end
            };
        }
        
        return token;
    }

    function rewindTo(index: number) {
        idx = index;
        // while (peek() && peek().type === TokenType.Whitespace) idx++;
    }

    function findNextNonwhitespaceOrEndIndex(): number {
        let i = idx;
        for (; i < tokens.length; i++) {
            if (tokens[i] && tokens[i].type !== TokenType.Whitespace) return tokens[i].range.start;
        }
        return tokens.length ? tokens[tokens.length - 1].range.end : 0;
    }

    function consumeIfMatch(tokenToken: FilterSymbol | string): Token | undefined {
        let token: Token;
        const savedSkippedWhitespace = skippedWhitespace;
        const savedLastSkippedWhitespace = lastSkippedWhitespace;
        const savedIdx = idx;
        const savedLastTokenRangeWithWhitespace = lastTokenRangeWithWhitespace;

        skippedWhitespace = [];
        lastSkippedWhitespace = undefined;
        
        do {
            consoleWrapper.groupCollapsed('checking', tokenToken, 'at index', idx);
            token = tokens[idx++];
            if (token && token.type === TokenType.Whitespace) {
                skippedWhitespace.push(token);
                lastSkippedWhitespace = token;
            }

            log.debug('consuming token', token, 'at index', idx);
            consoleWrapper.groupEnd();
        } while (token && token.type === TokenType.Whitespace); // skip whitespace tokens

        if (!token || token.token !== tokenToken) {
            skippedWhitespace = savedSkippedWhitespace;
            lastSkippedWhitespace = savedLastSkippedWhitespace;
            lastTokenRangeWithWhitespace = savedLastTokenRangeWithWhitespace;
            idx = savedIdx;
            return;
        }

        lastTokenRangeWithWhitespace = {
            start: skippedWhitespace[0] ? skippedWhitespace[0].range.start : token.range.start,
            end: token.range.end
        };

        return token;
    }

    function check<T extends Ast.AstNode>(test: Test<T>, desc?: string): ParserTestResult<T> {
        return expect(test, desc);
        // consoleWrapper.groupCollapsed('testing', desc, 'at index', idx);
        // const startIdx = idx;
        // if (skippedTests.indexOf(test) !== -1) {
        //     log.debug('tested', desc, 'skipped');
        //     consoleWrapper.groupEnd();
        //     return undefined;
        // }
        // const res = test();
        // if (!res) {
        //     log.debug('tested', desc, false, 'and reset index from', idx, 'back to', startIdx);
        //     rewindTo(startIdx);
        //     consoleWrapper.groupEnd();
        //     return undefined;
        // } else if (res.valid) {
        //     log.debug('tested', desc, true);
        //     consoleWrapper.groupEnd();
        //     return res;
        // } else { // invalid
        //     log.debug('tested', desc, res, 'and reset index from', idx, 'back to', startIdx);
        //     rewindTo(startIdx);
        //     consoleWrapper.groupEnd();
        //     return res;
        // }
    }

    function expect<T extends Ast.AstNode>(test: Test<T>, desc?: string): ParserTestResult<T> {
        consoleWrapper.groupCollapsed('expecting', desc, 'at index', idx);
        const startIdx = idx;
        const res = test();
        if (res) {
            log.debug('expected', desc, res);
            consoleWrapper.groupEnd();
            return res;
        } else {
            log.debug('expected', desc, res, 'and reset index from', idx, 'back to', startIdx);
            rewindTo(startIdx);
            consoleWrapper.groupEnd();
            // emitError(`expected ${desc}`);
            return res;
        }
    }

    function findFieldByName(name: string, sourceTable?: string) {
        let matching = fields.filter(x => x.name.toLowerCase() === name.toLowerCase());
        if (matching.length <= 1) return matching[0];
        matching = matching.filter(x => x.sourceTable === sourceTable);
        if (matching.length <= 1) return matching[0];
        return matching.find(x => x.name === name); // if multiple options exist, exact match only
    }

    // expression := <binaryExpression> | <term>
    function expression(): Ast.AstNode | undefined {
        let res: Ast.AstNode | undefined;

        for (let nextPrec = binaryOperatorPrecedences.length - 1; nextPrec >= 0 && !res; nextPrec--) {
            res = check(() => binaryExpression(nextPrec), `binaryExpression (level ${nextPrec})`);
        }
        // res = check(binaryExpression, 'binaryExpression');
        if (res) return res;
        
        res = check(term, 'term');
        if (res) return res;
        
        return undefined;
    }

    // term := <parenExpression> | <value> | <functionCall> | <field>
    function term(): ParserTestResult {
        let res: ParserTestResult;

        res = check(unaryExpression, 'unaryExpression');
        if (res) return res;

        res = check(parenExpression, 'parenExpression');
        if (res) return res;
        
        res = check(value, 'value');
        if (res) return res;
        
        res = check(functionCall, 'functionCall');
        if (res) return res;
        
        res = check(field, 'field');
        if (res) return res;
        
        return undefined;
    }

    // parenExpression := '(' <expression> ')'
    function parenExpression(): ParserTestResult<Ast.ParentheticalNode> {
        const openParen = consumeIfMatch('(');
        if (!openParen) return undefined;
        // consume the expression
        const content = expect(expression, 'expression');
        if (!content) {
            return {
                valid: false,
                dataType: null,
                errors: [{error: 'Expected expression.'}],
                range: openParen.range,
                type: Ast.AstNodeType.paren,
                content: null,
                openParenRange: openParen.range,
                contentRange: {start: openParen.range.end + 1, end: lastSkippedWhitespace ? lastSkippedWhitespace.range.end : openParen.range.end + 1},
                closeParenRange: {start: openParen.range.end + 1, end: lastSkippedWhitespace ? lastSkippedWhitespace.range.end : openParen.range.end + 1}
            };
        }
        const closeParen = consumeIfMatch(')');
        if (!closeParen) {
            return {
                valid: false,
                dataType: content.dataType,
                errors: [{error: 'Missing terminating close-parenthesis.'}],
                range: {start: openParen.range.start, end: content.range.end},
                type: Ast.AstNodeType.paren,
                content,
                contentRange: content.range,
                openParenRange: openParen.range,
                closeParenRange: {start: content.range.end + 1, end: lastSkippedWhitespace ? lastSkippedWhitespace.range.end : content.range.end + 1}
            };
        }

        return {
            valid: true,
            dataType: content.dataType,
            errors: [],
            range: {start: openParen.range.start, end: closeParen.range.end},
            type: Ast.AstNodeType.paren,
            content,
            contentRange: content.range,
            openParenRange: openParen.range,
            closeParenRange: closeParen.range
        };
    }

    // binaryExpression := (<binaryExpression (higher precedence)> | <term>) binaryOperator (<binaryExpression (same or higher precedence)> | <term>)
    function binaryExpression(precedence = binaryOperatorPrecedences.length - 1): ParserTestResult<Ast.BinaryOperationNode> {
        if (precedence < 0) return undefined;

        const start = idx;

        const operand1Start = lastRealToken ? lastRealToken.range.end : 0;
        // skippedTests.push(booleanExpression);
        let firstExpression: ParserTestResult<Ast.AstNode>;
        for (let nextPrec = precedence - 1; nextPrec >= 0 && !firstExpression; nextPrec--) {
            firstExpression = check(() => binaryExpression(nextPrec), `higher precedence (level ${nextPrec}) binary expression (operand1)`);
        }
        if (!firstExpression) firstExpression = check(term, 'term (operand1)');
        if (!firstExpression) {
            // skippedTests.pop();
            return undefined;
        }
        // skippedTests.pop();

        const operator = consume();
        if (!operator) {
            // if (firstExpression.type === Ast.AstNodeType.binaryOperation) return firstExpression as Ast.BinaryOperationNode;

            return undefined;
        }

        const operand1Range: Range = {start: operand1Start, end: operator.range.start};
        const operatorStart = lastTokenRangeWithWhitespace.start;

        if (operator.type !== TokenType.Symbol || allBinaryOperators.indexOf(operator.token) === -1) {
            log.debug(operator.token.toLowerCase(), 'is not a valid operator');
            return undefined;
        }
        const operatorRowIndex = binaryOperatorPrecedences.findIndex(row => row.includes(operator.token as BinaryOperator));
        if (operatorRowIndex === -1) {
            log.error('Encountered an operator that is supposedly valid, but has no precedence:', operator.token);
            return undefined;
        } else if (operatorRowIndex > precedence) {
            log.debug('Operator found but of too low precedence:', operator.token, '--', operatorRowIndex, '>', precedence);
            return undefined;
        }

        let dataType: FieldTypeCategory | null;
        if (operator.token in LogicalOperator || operator.token in NumericComparisonOperator || operator.token in StringComparisonOperator) {
            dataType = FieldTypeCategory.Boolean;
        } else {
            dataType = firstExpression.dataType;
        }

        let secondExpression: ParserTestResult<Ast.AstNode>;
        for (let nextPrec = precedence; nextPrec >= 0 && !secondExpression; nextPrec--) {
            secondExpression = check(() => binaryExpression(nextPrec), `same or higher precedence (level ${nextPrec}) binary expression (operand2)`);
        }
        if (!secondExpression) secondExpression = check(term, 'term (operand2)');
        const operatorEnd = lastSkippedWhitespace ? lastSkippedWhitespace.range.end : operator.range.end;
        const operatorRange: Range = {start: operatorStart, end: operatorEnd};
        if (!secondExpression) {
            return {
                valid: false,
                dataType,
                errors: [{error: 'Expected second operand', }],
                range: {start, end: operator.range.end},
                type: Ast.AstNodeType.binaryOperation,
                operand1: firstExpression,
                operand1Range,
                operand2: null,
                operand2Range: {start: operator.range.end, end: lastSkippedWhitespace ? lastSkippedWhitespace.range.end : operator.range.end + 1},
                operator: operator.token,
                operatorRange
            };
        }

        const operand2End = findNextNonwhitespaceOrEndIndex();
        const operand2Range = {start: operator.range.end, end: operand2End};

        const allowedOperandTypes: FieldTypeCategory[] = [];
        if (operator.token.toLowerCase() in LogicalOperator) allowedOperandTypes.push(FieldTypeCategory.Boolean);
        if (operator.token.toLowerCase() in MathOperator) allowedOperandTypes.push(FieldTypeCategory.Double);
        if (operator.token.toLowerCase() in StringComparisonOperator) allowedOperandTypes.push(FieldTypeCategory.String);
        if (operator.token.toLowerCase() in NumericComparisonOperator) allowedOperandTypes.push(FieldTypeCategory.Double);

        if (firstExpression.dataType === null || allowedOperandTypes.indexOf(firstExpression.dataType) === -1) {
            return {
                valid: false,
                dataType,
                errors: [{error: `Operator expects operands of type${allowedOperandTypes.length === 1 ? 's' : ''} ${allowedOperandTypes.map(x => FieldTypeCategory[x]).join(', ')}, but found ${FieldTypeCategory[firstExpression.type]}`, range: operator.range}],
                range: {start, end: secondExpression.range.end},
                type: Ast.AstNodeType.binaryOperation,
                operand1: firstExpression,
                operand2: secondExpression,
                operand1Range,
                operand2Range,
                operator: operator.token,
                operatorRange,
            };
        }

        if (firstExpression.dataType !== secondExpression.dataType) {
            return {
                valid: false,
                dataType,
                errors: [{error: `Expected ${FieldTypeCategoryNames.get(firstExpression.dataType)}, but got ${FieldTypeCategoryNames.get(secondExpression.dataType!)}.`, range: secondExpression.range}],
                range: {start, end: secondExpression.range.end},
                type: Ast.AstNodeType.binaryOperation,
                operand1: firstExpression,
                operand2: secondExpression,
                operand1Range,
                operand2Range,
                operator: operator.token,
                operatorRange,
            };
        }

        return {
            valid: true,
            dataType,
            errors: [],
            range: {start, end: secondExpression.range.end},
            type: Ast.AstNodeType.binaryOperation,
            operand1: firstExpression,
            operand2: secondExpression,
            operand1Range,
            operand2Range,
            operatorRange,
            operator: operator.token
        };
    }

    // unaryExpression := unaryOperator <expression>
    function unaryExpression(): ParserTestResult<Ast.UnaryOperationNode> {
        const start = idx;
        
        const operator = consume();
        if (!operator) return undefined;
        if (operator.type !== TokenType.Symbol || allUnaryOperators.indexOf(operator.token) === -1) {
            log.debug(operator.token.toLowerCase(), 'is not a valid unary operator');
            return undefined;
        }

        const expr = expect(expression, 'valid operand (expression)');
        if (!expr) {
            return {
                valid: false,
                dataType: null,
                errors: [{error: 'Expected operand', }],
                range: {start, end: operator.range.end},
                type: Ast.AstNodeType.unaryOperation,
                operand: null,
                operator: operator.token,
                operatorRange: operator.range,
                operandRange: {start: operator.range.end, end: lastSkippedWhitespace ? lastSkippedWhitespace.range.end : operator.range.end + 1}
            };
        }

        return {
            valid: true,
            dataType: expr.dataType,
            errors: [],
            range: {start, end: idx},
            type: Ast.AstNodeType.unaryOperation,
            operand: expr,
            operator: operator.token,
            operatorRange: operator.range,
            operandRange: {start: operator.range.end, end: lastSkippedWhitespace ? lastSkippedWhitespace.range.end : expr.range.end + 1}
        };
    }

    // value := string | number
    function value(): ParserTestResult<Ast.NumberNode | Ast.StringNode> {
        const token = consume();
        if (!token) return undefined;

        const text = token.token;

        if (token.type === TokenType.String || token.type === TokenType.Regex) {
            // the following commented out block is likely no longer needed because the lexer ensures strings are appropriately wrapped with quotes in order to tag them as a string

            // eslint-disable-next-line quotes
            // if (//(text[0] !== "'" && text[0] !== '"')
            //     text[0] !== text[text.length - 1]
            //     || text.length < 2) {

            //     // only one quote, or no starting/ending quote
            //     return {
            //         valid: false,
            //         dataType: FieldTypeCategory.String,
            //         textRange: {start: token.range.start + 1, end: token.range.end},
            //         errors: [{error: 'Invalid string; strings must be surrounded by quotes'}],
            //         range: token.range,
            //         isRegex: false,
            //         type: Ast.AstNodeType.string,
            //         value: null,
            //         isUnquoted: false,
            //     };
            // }

            const stripped = token.token.substring(1, token.token.length - 1);
            const base: Ast.StringNode = {
                valid: true,
                dataType: FieldTypeCategory.String,
                errors: [],
                range: token.range,
                textRange: {start: token.range.start + 1, end: token.range.end - 1},
                type: Ast.AstNodeType.string,
                value: stripped,
                isRegex: token.token[0] === '/',
                isUnquoted: false,
            };
            if (stripped.startsWith('$')) {
                return {...base, isRegex: false, value: stripped.substring(1)};
            } else if (stripped.startsWith('%')) {
                return {...base, isRegex: true, value: stripped.substring(1)};
            } else { // todo: use context clues
                return {...base};
            }
        } else if (token.type === TokenType.Number) {
            return {
                valid: true,
                dataType: FieldTypeCategory.Double,
                errors: [],
                range: token.range,
                type: Ast.AstNodeType.number,
                value: +token.token
            };
        }
        // else if (token.type === TokenType.Name && tokens.length === 1 && !findFieldByName(token.token)) {
        //     // special case to accept unquoted strings as the one and only token, if its not also the name of a field
        //     return {
        //         valid: true,
        //         dataType: FieldTypeCategory.String,
        //         errors: [],
        //         range: token.range,
        //         type: Ast.AstNodeType.string,
        //         value: token.token,
        //         isRegex: false,
        //         isUnquoted: true,
        //     };
        // }
        
        log.debug(token, 'is not a value', typeof token);
        return undefined;
    }

    // functionCall := functionName '(' <argListPiece> ')'
    function functionCall(): ParserTestResult<Ast.FunctionCallNode> {
        const functionNameIndex = idx;
        const functionNameToken = consume();
        if (!functionNameToken) return undefined;
        const functionNameRange = lastTokenRangeWithWhitespace;

        const func = functionDefs.find(f => f.name.toLowerCase() === functionNameToken.token.toLowerCase());
        // do not yet validate the func, we need to find all the rest of the expected pieces first for the syntactical check

        const openParen = consumeIfMatch('(');
        if (!openParen) return undefined;
        const openParenRange = lastTokenRangeWithWhitespace;

        if (lastSkippedWhitespace) functionNameRange.end = lastSkippedWhitespace.range.end;

        // at this point we have a function, so validate

        const args: Ast.AstNode[] = [];
        const argumentRanges: Range[] = [];
        let closeParen = consumeIfMatch(')');
        let closeParenRange = closeParen ? lastTokenRangeWithWhitespace : undefined;
        let lastComma: Token | undefined;
        const errors: Ast.ParserError[] = [];
        while (!closeParen) { // && idx <= lastIdx) {
            const prevComma = lastComma;

            const argStart = prevComma ? prevComma.range.end : openParen.range.end; //lastTokenRangeWithWhitespace.end;
            const arg = expect(expression, 'expression');

            lastComma = consumeIfMatch(',');
            closeParen = lastComma ? undefined : consumeIfMatch(')');
            closeParenRange = closeParen ? lastTokenRangeWithWhitespace : undefined;

            const argEnd = closeParen ? closeParen.range.start : (lastComma ? lastComma.range.start : (arg ? arg.range.end : findNextNonwhitespaceOrEndIndex()));
            const argRange: Range = {start: argStart, end: argEnd};
            argumentRanges.push(argRange);

            if (arg) {
                args.push(arg);
            } else {
                errors.push({error: 'Expected argument expression', range: argRange});
            }

            if (closeParen) break;

            if (!lastComma) {
                // Didn't break due to a close paren, and, we didn't find a comma to mark the next argument.
                // So, we're missing the close paren.

                return {
                    valid: false,
                    dataType: func ? func.returnType : null,
                    functionDef: func || null,
                    functionNameRange,
                    openParenRange,
                    closeParenRange: null,
                    argumentsRange: args.length ? {start: openParen.range.start, end: args[args.length - 1].range.end} : openParen.range,
                    errors: [...errors, {error: 'Expected closing parenthesis'}],
                    range: {start: functionNameToken.range.start, end: argEnd},
                    type: Ast.AstNodeType.functionCall,
                    arguments: args,
                    argumentRanges,
                    functionName: functionNameToken.token
                };
            }
        }
        
        if (!closeParen) {
            return {
                valid: false,
                dataType: func ? func.returnType : null,
                functionDef: func || null,
                functionNameRange,
                openParenRange,
                closeParenRange: null,
                argumentsRange: args.length ? {start: openParen.range.start, end: lastComma ? lastComma.range.end : args[args.length - 1].range.end} : openParen.range,
                errors: [...errors, {error: 'Missing close-parenthesis for function call.'}],
                range: {start: functionNameToken.range.start, end: lastComma ? lastComma.range.end : (args.length ? args[args.length - 1].range.end : openParen.range.end)},
                type: Ast.AstNodeType.functionCall,
                arguments: args,
                argumentRanges,
                functionName: functionNameToken.token
            };
        }

        if (!func) {
            log.debug(functionNameToken, 'matches no functions');
            return {
                valid: false,
                dataType: null,
                functionDef: func || null,
                functionNameRange,
                openParenRange,
                closeParenRange: closeParenRange!,
                argumentsRange: {start: openParen.range.start, end: closeParen.range.end},
                errors: [...errors, {error: `${functionNameToken.token} matches no functions`, range: functionNameToken.range}],
                range: { start: functionNameToken.range.start, end: closeParen.range.end },
                type: Ast.AstNodeType.functionCall,
                arguments: args,
                argumentRanges,
                functionName: functionNameToken.token
            };
        }

        if (func.params.length !== args.length) {
            return {
                valid: false,
                dataType: func.returnType,
                functionDef: func || null,
                functionNameRange,
                openParenRange,
                closeParenRange: closeParenRange!,
                argumentsRange: {start: openParen.range.start, end: closeParen.range.end},
                errors: [...errors, {
                    error: `Expected ${func.params.length} argument${func.params.length !== 1 ? 's' : ''}, but `
                        + `${args.length === 0 ? 'none' : `${args.length < func.params.length ? 'only ' : ''}${args.length}`} ${args.length !== 1 ? 'are' : 'is'} present`
                }],
                range: { start: functionNameToken.range.start, end: closeParen.range.end },
                type: Ast.AstNodeType.functionCall,
                arguments: args,
                argumentRanges,
                functionName: functionNameToken.token
            };
        }

        for (let pi = 0; pi < func.params.length; pi++) {
            const param = func.params[pi];
            const arg = args[pi];
            const last = func.params.length - 1 === pi;

            // const allowedTypesLen = allowedTypes.length;
            if (!arg) {
                return {
                    valid: false,
                    dataType: func.returnType,
                    functionDef: func || null,
                    functionNameRange,
                    openParenRange,
                    closeParenRange: closeParenRange!,
                    argumentsRange: {start: openParen.range.start, end: closeParen.range.end},
                    errors: [...errors, {error: `Expected argument ${pi} (${param.name}) of type ${FieldTypeCategoryNames.get(param.type)}`}],
                    range: { start: functionNameToken.range.start, end: closeParen.range.end },
                    type: Ast.AstNodeType.functionCall,
                    arguments: args,
                    argumentRanges,
                    functionName: functionNameToken.token
                };
            }

            if (arg.dataType !== param.type) {
                return {
                    valid: false,
                    dataType: func.returnType,
                    functionDef: func || null,
                    functionNameRange,
                    openParenRange,
                    closeParenRange: closeParenRange!,
                    argumentsRange: {start: openParen.range.start, end: closeParen.range.end},
                    errors: [...errors, {error: `Argument of type ${FieldTypeCategoryNames.get(param.type)} expected, but found ${FieldTypeCategoryNames.get(arg.dataType!)}`, range: arg.range}],
                    range: { start: functionNameToken.range.start, end: closeParen.range.end },
                    type: Ast.AstNodeType.functionCall,
                    arguments: args,
                    argumentRanges,
                    functionName: functionNameToken.token
                };
            }
        }

        // emitCompletionOptions([{text: ')', type: CompletionType.syntax}]);
        return {
            valid: errors.length === 0,
            dataType: func.returnType,
            errors,
            functionDef: func || null,
            functionNameRange,
            openParenRange,
            closeParenRange: closeParenRange!,
            argumentsRange: {start: openParen.range.start, end: closeParen.range.end},
            range: {start: functionNameToken.range.start, end: closeParen.range.end},
            type: Ast.AstNodeType.functionCall,
            arguments: args,
            argumentRanges,
            functionName: functionNameToken.token
        };
    }

    function field(): ParserTestResult<Ast.ColumnNode> {
        const fieldNameToken = consume();
        if (!fieldNameToken) return undefined;
        if (fieldNameToken.type !== TokenType.Name) {
            log.debug(fieldNameToken, 'is not a name');
            return undefined;
        }

        const atToken = consumeIfMatch('@');
        const sourceTableToken = atToken && consume();

        const lastToken = sourceTableToken || atToken || fieldNameToken;

        // if it exists and its a name, it must be a field (so expect it)
        const foundField = findFieldByName(fieldNameToken.token, sourceTableToken && sourceTableToken.token);
        if (!foundField) {
            return {
                valid: false,
                dataType: null,
                errors: [{error: 'Column not found'}],
                range: {start: fieldNameToken.range.start, end: lastToken.range.end},
                type: Ast.AstNodeType.column,
                name: fieldNameToken.token,
                field: null,
                sourceTable: sourceTableToken ? sourceTableToken.token : null
            };
        }

        const types = foundField.type;

        // todo: types[0] should instead become handling of multiple types
        return {
            valid: true,
            dataType: types,
            errors: [],
            range: fieldNameToken.range,
            type: Ast.AstNodeType.column,
            name: foundField.name,
            field: foundField,
            sourceTable: foundField.sourceTable || null
        };
    }

    // start the process with the base level expression
    const rootNode = check(expression, 'root expression');

    const errors: Required<Ast.ParserError>[] = [];

    let valid = true;
    if (rootNode && rootNode.valid && idx !== tokens.length && tokens.filter((x, i) => i >= idx).some(x => x.type !== TokenType.Whitespace)) {
        valid = false;
        errors.push({error: 'Unexpected text at end of expression', range: {start: tokens[idx].range.end + 1, end: tokens[tokens.length - 1].range.end}});
        log.warn('extra tail end tokens found');
        // for (let i = idx+1; i < tokens.length; i++)
        //     emitError('unexpected text at end of expression', i);
    }

    // end group a lot, just in case
    consoleWrapper.groupEnd();
    consoleWrapper.groupEnd();
    consoleWrapper.groupEnd();
    consoleWrapper.groupEnd();
    consoleWrapper.groupEnd();

    // for (const section of sections) {
    //     section.completionOptions = _.uniqWith(section.completionOptions, _.isEqual);
    // }
    
    // const overallResult: ParserResult = rootRes.valid ? {
    //     valid,
    //     sections,
    //     errors,
    //     type: rootRes.type,
    //     compiled: rootRes.string
    // } : {
    //     valid: false,
    //     sections,
    //     errors
    // };

    // annotate with parents
    function annotateAndTraverse(node: Ast.AstNode | undefined) {
        const children = childrenOfNode(node);
        for (const child of children) {
            child.parent = node;
            annotateAndTraverse(child);
        }
    }

    annotateAndTraverse(rootNode);
    
    const result = { valid, errors, root: rootNode, text };
    log.info('Parser result', result);
    log.info('AST', rootNode);
    return result;
}

export default parser;

// tslint:disable-next-line: no-string-literal
window['astParser'] = (x: string) => parser(lexer(x), []);
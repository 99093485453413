import wu from 'wu';

import { Token, TokenType } from './model';
import { FilterSymbol, Symbols } from './symbols';

import loglevel from 'loglevel';
const log = loglevel.getLogger('lexer');
log.setDefaultLevel('INFO');

enum MachineState {
    ReadyForToken = 'ready',
    ParsingWhitespace = 'ws',
    ParsingName = 'name',
    ParsingNumber = 'number',
    ParsingString = 'str',
    ParsingRegex = 'regex',
    ParsingSymbol = 'sym',
    TokenBuildingFinished = 'done'
}

export function isWhitespace(input: string) {
    if (!input) return false;
    
    for (let i = 0; i < input.length; i++) {
        const char = input[i];

        if (!(char === ' ' || char === String.fromCharCode(160) || char === '\t' || char === '\r' || char === '\n')) {
            return false;
        }
    }

    return true;
}

function lexer(input: string): Token[] {
    /*
    for (int idx = 1; idx <= LanguageSymbols.Symbols.Length; ++idx)
    {
        symbols.Write(LanguageSymbols.Symbols[idx]);
        symbols.Write('\t');
        symbols.WriteLine(idx);
    }
    */

    log.debug('lexer got', `'${input}'`);

    const tokens: Token[] = [];
    
    let currentToken = '';

    let nextState: MachineState = MachineState.ReadyForToken;
    let state: MachineState;
    let prevState: MachineState = MachineState.ReadyForToken;

    if (!input || !input.length) {
        return tokens;
    }

    let col = 0;
    let last = input[col++];
    const next = input[col];
    let startCol: number | undefined;
    let notFinished = true;
    while (notFinished) {
        // if (col >= input.length)
        //     nextState = MachineState.TokenBuildingFinished;
        
        state = nextState;
        switch (nextState) {
            case MachineState.ReadyForToken:
                currentToken = '';
                startCol = col - 1;
                if (last === undefined) {
                    notFinished = false;
                } else if (isWhitespace(last)) { // 160 is a nbsp
                    nextState = MachineState.ParsingWhitespace;
                    currentToken += last;
                } else if (last === '.' || ('0' <= last && last <= '9')) { // a number
                    nextState = MachineState.ParsingNumber;
                    currentToken += last;
                } else if (('a' <= last && last <= 'z')
                    || ('A' <= last && last <= 'Z')
                    || last === '_'
                    || (last === '%' && !isWhitespace(next))
                    || last === '~'
                ) {
                    nextState = MachineState.ParsingName;
                    currentToken += last;
                } else if (last === '/' && (tokens.length === 0 || tokens[tokens.length - 1].type === TokenType.Symbol)) {
                    currentToken += last;
                    nextState = MachineState.ParsingRegex;
                } else if (wu(Symbols.keys()).some(x => x[0] === last)) {
                    nextState = MachineState.ParsingSymbol;
                    currentToken += last;
                // eslint-disable-next-line quotes
                } else if (last === "'" || last === '"') {
                    nextState = MachineState.ParsingString;
                    currentToken += last;
                } else {
                    // console.log('bad symbol', last.charCodeAt(0), ' '.charCodeAt(0), last == ' ');
                    tokens.push({range: {start: col, end: col}, token: last, type: TokenType.Error, error: 'Invalid symbol'});
                    last = input[col++];
                }
                break;

            case MachineState.ParsingRegex:
                last = input[col++];
                if (!last) {
                    nextState = MachineState.TokenBuildingFinished;
                } else if (last === currentToken[0]) { // must match the open quote
                    currentToken += last;
                    last = input[col++]; // move to one past quote
                    nextState = MachineState.TokenBuildingFinished;
                } else {
                    currentToken += last;
                }
                break;

            case MachineState.ParsingString:
                last = input[col++];
                if (!last) {
                    nextState = MachineState.TokenBuildingFinished;
                } else if (last === currentToken[0]) { // must match the open quote
                    currentToken += last;
                    last = input[col++]; // move to one past quote
                    nextState = MachineState.TokenBuildingFinished;
                } else {
                    currentToken += last;
                }
                break;

            case MachineState.ParsingName:
                last = input[col++];
                if (('a' <= last && last <= 'z')
                    || ('A' <= last && last <= 'Z')
                    || ('0' <= last && last <= '9')
                    || last === '_'
                    || last === '~'
                    || (last === '%' && !isWhitespace(next))
                ) {
                    currentToken += last;
                } else {
                    if (currentToken in FilterSymbol) {
                        state = MachineState.ParsingSymbol;
                    } // lie to the next state to make the name into a symbol
                    nextState = MachineState.TokenBuildingFinished;
                }
                
                break;

            case MachineState.ParsingWhitespace:
                last = input[col++];
                if (isWhitespace(last)) {
                    currentToken += last;
                } else {
                    nextState = MachineState.TokenBuildingFinished;
                }
                break;

            case MachineState.ParsingNumber:
                last = input[col++];
                if ('0' <= last && last <= '9') {
                    currentToken += last;
                } else if (last === '.' && !currentToken.includes('.')) {
                    // consume and add a decimal point, but only if there are already no decimal points
                    currentToken += last;
                } else {
                    nextState = MachineState.TokenBuildingFinished;
                }
                break;

            case MachineState.ParsingSymbol:
                last = input[col++];
                const potential = currentToken + last;
                if (wu(Symbols.keys()).some(x => x.indexOf(potential) === 0)) {
                    currentToken += last;
                } else {
                    nextState = MachineState.TokenBuildingFinished;
                }
                break;

            case MachineState.TokenBuildingFinished:
                if (currentToken === '') {
                    notFinished = false;
                } else if (prevState === MachineState.ParsingSymbol) {
                    const matchedSymbol: FilterSymbol = Symbols.get(currentToken)!;
                    if (matchedSymbol === undefined) {
                        tokens.push({range: {start: startCol!, end: col - 1}, token: currentToken, type: TokenType.Error, error: 'Invalid operator/symbol'});
                    } else {
                        tokens.push({range: {start: startCol!, end: col - 1}, token: matchedSymbol, type: TokenType.Symbol});
                    }
                } else if (prevState === MachineState.ParsingName) {
                    const matchedSymbol: FilterSymbol = Symbols.get(currentToken)!;
                    if (matchedSymbol === undefined) {
                        tokens.push({range: {start: startCol!, end: col - 1}, token: currentToken, type: TokenType.Name});
                    } else { // handles things like the LIKE/AND/OR operators
                        tokens.push({range: {start: startCol!, end: col - 1}, token: matchedSymbol, type: TokenType.Symbol});
                    }
                } else if (prevState === MachineState.ParsingString) {
                    tokens.push({range: {start: startCol!, end: col - 1}, token: currentToken, type: TokenType.String});
                } else if (prevState === MachineState.ParsingRegex) {
                    tokens.push({range: {start: startCol!, end: col - 1}, token: currentToken, type: TokenType.Regex});
                } else if (prevState === MachineState.ParsingWhitespace) {
                    tokens.push({range: {start: startCol!, end: col - 1}, token: currentToken, type: TokenType.Whitespace});
                } else if (prevState === MachineState.ParsingNumber) {
                    const potentialFloat = parseFloat(currentToken);
                    if (isNaN(potentialFloat)) {
                        tokens.push({range: {start: startCol!, end: col - 1}, token: currentToken, error: 'Invalid number', type: TokenType.Error});
                    } else {
                        tokens.push({range: {start: startCol!, end: col - 1}, token: currentToken, type: TokenType.Number});
                    }
                } else {
                    log.error('Invalid state transition! Previous state:', prevState, 'Current token:', currentToken); // uhho
                    return tokens;
                }

                nextState = MachineState.ReadyForToken;
                // if (col > input.length)
                //     return tokens;

                break;
        }

        prevState = state;
    }

    log.debug('lexer produced', tokens);

    return tokens;
}

export default lexer;
import { Universe, UniverseCreatorResult, UniverseAspectType, UniverseAspectApplier } from './model';

import {rangedUniverseAspectApplier} from './creators/ranged/definer';
import { DefinitionBundle, ObjectDefinition, Table, ViewDefinition } from '@thinkalpha/table-client';
import { randomString } from '../util/randomString';

const inclusionListMoniker = 'inclusionList';
const exclusionListMoniker = 'exclusionList';
const exclusionToInclusionListMoniker = 'exclusionToInclusionList';
const filterViewMoniker = 'filters';
const finalTableMoniker = 'filtered';

export function defineUniverse(universe: Universe): UniverseCreatorResult {
    if (universe.tableName) return {name: universe.tableName, definitions: []};
    
    const name = randomString();
    
    const definitions: DefinitionBundle = [];
    
    const exclusionListDefs: DefinitionBundle | undefined = (!universe.exclusionList.length || universe.inclusionList.length) ? undefined : [
        {
            table: {
                name: `${name}-${exclusionListMoniker}`,
                key_column: [
                    {
                        name: 'excludedSymbol',
                        type: 'string',
                        desc: 'Symbol'
                    }
                ]
            }
        }, {
            keys: [
                {
                    table: `${name}-${exclusionListMoniker}`,
                    add_keys: universe.exclusionList.map(x => `${x}:*`),
                }
            ]
        }, {
            table: {
                name: `${name}-${exclusionListMoniker}-joined`,
                auto_sources: [
                    {
                        name: 'allUniverse',
                        columns: ['.*']
                    }, {
                        name: `${name}-${exclusionListMoniker}`,
                        columns: ['.*'],
                        match_key: {
                            primary: [
                                {
                                    on_left_side: 'symbol',
                                    on_right_side: 'excludedSymbol'
                                }
                            ]
                        }
                    }
                ]
            }
        }, {
            views: [{
                table: `${name}-${exclusionListMoniker}-joined`,
                name: `${exclusionToInclusionListMoniker}`,
                filter: `str_cmp(excludedSymbol, '') == 0`
            }]
        }, {
            table: {
                name: `${name}-${inclusionListMoniker}`,
                auto_sources: [
                    {
                        name: `${name}-${exclusionListMoniker}-joined`,
                        view: `${exclusionToInclusionListMoniker}`,
                        columns: ['.*']
                    }
                ]
            }
        }
    ];
    if (exclusionListDefs) definitions.push(...exclusionListDefs);

    const inclusionMinusExclusion = universe.exclusionList.length ? universe.inclusionList.filter(x => !universe.exclusionList.includes(x)) : universe.inclusionList;
    const inclusionListDefs: DefinitionBundle | undefined = !universe.inclusionList.length ? undefined : [
        {
            table: {
                name: `${name}-${inclusionListMoniker}`,
                key_column: [
                    {
                        name: 'symbol',
                        type: 'string',
                        desc: 'Symbol'
                    }
                ]
            }
        }, {
            keys: [
                {
                    table: `${name}-${inclusionListMoniker}`,
                    add_keys: inclusionMinusExclusion.map(x => `${x}:*`),
                }
            ]
        }
    ];
    if (inclusionListDefs) definitions.push(...inclusionListDefs);

    const primaryTableDefinition: Table = {
        name,
        auto_sources: [
            ...(inclusionListDefs || exclusionListDefs) ? [{
                name: `${name}-${inclusionListMoniker}`,
                columns: []
            }] : [], {
                name: 'allUniverse',
                columns: ['.*'],
                match_key: {
                    primary: [
                        {
                            on_left_side: 'symbol',
                            on_right_side: 'symbol'
                        }
                    ]
                }
            }
        ]
    };
    const filters: string[] = [];
    
    for (const aspect of universe.aspects) {
        const getApplier = getUniverseAspectApplier(aspect.type);
        const {newDefinitions, filter} = getApplier(aspect, primaryTableDefinition);
        definitions.push(...newDefinitions);
        if (filter) filters.push(filter);
    }

    definitions.push({table: primaryTableDefinition});
    if (filters.length) {
        definitions.push(
            {
                views: [
                    {
                        table: name,
                        name: filterViewMoniker,
                        filter: filters.map(x => `(${x})`).join(' and ')
                    }
                ]
            }, {
                table: {
                    name: `${name}-${finalTableMoniker}`,
                    auto_sources: [
                        {
                            name,
                            view: filterViewMoniker,
                            columns: ['.*'],
                            match_key: {
                                primary: [
                                    {
                                        on_left_side: 'symbol',
                                        on_right_side: 'symbol'
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        );
    }

    let finalName = filters.length ? `${name}-${finalTableMoniker}` : name;

    if (definitions.length <= 1) {
        definitions.splice(0, 1);
        finalName = 'allUniverse';
    }

    return {definitions, name: finalName};
}

function getUniverseAspectApplier(type: UniverseAspectType): UniverseAspectApplier {
    switch (type) {
        case UniverseAspectType.marketCap:
        case UniverseAspectType.price:
        case UniverseAspectType.volume:
        case UniverseAspectType.currentRatio:
        case UniverseAspectType.peRatio:
            return rangedUniverseAspectApplier;
        // case UniverseAspectType.premade:
        //     return createPremadeUniverseAspect(universe);
        default:
            return null as never;
    }
}